.bar{
    width: 70px;
    height: 10px;
}

.progress-card{
    padding: 16px;
    flex: none;
    margin-right: 10px;
}

.progress-icon{
    width: 15px;
    height: 15px;
}

.goal-progress-popup-height{
    margin-top: calc(100vh - 93vh) !important;
    max-height: 83vh;
}

.progress-close-icon{
    position: absolute;
    top: 22px;
    right: 20px;
}