.slant {
    background: var(--primary-color-v2);
    color: var(--secondary-color-v2);
    position: relative;
    z-index: 1;
    margin-top: -1px;
    margin-bottom: 200px;
    height: 34vh;
}

.slant:after {
    background: inherit;
    bottom: -100px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-3deg);
    transform-origin: 100%;
    z-index: -1;
}

.slant .h1 {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
}