.role-heading{
    font-size: 15px;
    color: rgb(85, 82, 82);
}

.role-profile{
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}

.member-profile{
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    padding: 2px;
    background: var(--primary-color);
}

.word-break-all{
    word-break: break-all;
}

.member-detail-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

@media screen and (max-width : 700px) {
    .member-detail-grid{
        grid-template-columns: 1fr 1fr !important;
    }
}