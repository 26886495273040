/* .video-muted-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.screen-sharing-container{
    height: 88vh;
    padding: 10px;
    grid-gap : 10px;
    display: grid;
    margin: auto;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.screen-sharing-container.cell-1{
    grid-row: 1; 
    grid-column: 1/4; 
}

.screen-sharing-3X3{
    grid-row: 1/3;
    grid-column: 1/4;
}

.screen-sharing-4X4{
    grid-row: 1/4;
    grid-column: 1/4;
}

.screen-sharing-2X2{
    grid-row: 1/2;
    grid-column: 1/3;
}

.screen-sharing-1X1{
    grid-row: 1;
    grid-column: 1/3;
} */

/* .screen-sharing-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 5px;
} */

/* .screen-sharing-three-grid-column{
    grid-template-columns: 1fr 1fr 1fr !important;
}

.four-grid-row-column{
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.video-muted-container{
    position: relative;
    background: #2b2323;
}

.video-chat-container{
    position: relative;
    height: 100%;
    width: 100%;
}

.h-65pc{
    height: 65% !important;
}

.h-50vh{
    height: 50vh !important;
}

.videos{
    position: relative;
    height: 90vh;
    display: grid;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 10px;
    grid-gap : 10px
}

.two-grid-column{
    grid-template-columns: 1fr 1fr;
}

.two-grid-row{
    grid-template-rows: 1fr 1fr;
}

.three-grid-column{
    grid-template-columns: 1fr 1fr 1fr;
}

.three-grid-row{
    grid-template-rows: 1fr 1fr 1fr;
}

.vid{
    height: 100%;
    width: 100%;
}

.video-muted{
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-muted-user-name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    width: 100px;
    height: 100px;
    background: rgb(5, 71, 5);
    border-radius: 50%;
    color: white;
    font-weight: 700;
}

.desktop-videos-two-column{
    position: relative;
    height: 90vh;
    display: grid;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 10px;
    grid-gap : 10px;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width : 600px) {
    
}

@media screen and (min-width : 961px) {

}

.grid-container-3-by-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
}

.grid-container {
    display: grid;
}

.grid-one-cross-two {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
}

.grid-two-cross-two {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.grid-two-cross-three {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.two-col-grid-span-full {
    grid-column: span 2;
} */

/* .grid-item {
    background-color: antiquewhite;
} */

.grid-container {
    display: grid;
    grid-gap: 1rem;
}

.one-cross-two-grid {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
}

.two-cross-one-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
}

.two-cross-two-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.two-cross-three-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.three-cross-two-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.three-cross-three-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.three-cross-four-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
}

.four-cross-two-grid {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.span-two-wide{
    grid-column-start: 1;
    grid-column-end: 3;
}

.span-three-wide{
    grid-column-start: 1;
    grid-column-end: 4;
}

.span-two-tall{
    grid-row-start: 1;
    grid-row-end: 3;
}