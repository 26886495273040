.about-us-outer-image-container{
    width: 300px;
    height: 450px;
    border-radius: 10px;
    border: 3px solid var(--primary-color-v2);
    margin-top: 80px;
    margin-left: -80px;
    box-shadow: 6px 6px 7px 0 #4d78d64d, 3px 3px 8px 0 #4d78d64d inset
}

.about-us-image{
    width: 300px;
    height: 450px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

@media screen and (max-width : 660px) {
    .about-us-outer-image-container{
        width: 220px;
        height: 350px;
        margin-left: -40px;
        margin-top: 40px;
    }

    .about-us-image{
        width: 220px;
        height: 350px;
    }
}