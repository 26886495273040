.no-action:last-child hr{
    display: none;
}

.vc-hl-line{
    margin-top: 14px;
    margin-bottom: 14px;
    border-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
    border-style: solid;
    margin-left: 10%;
    margin-right: 10%;
    height: 1px;
}