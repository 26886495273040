.float-button{
    position: fixed;
    overflow: hidden;
    right: 30px;
    bottom: 43px;
    text-align: center;
}

.img-card{
    min-height: 100px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    background: white !important;
    border-radius: 1.5rem !important;
}

.fs-20{                 /*need to remove*/
    font-size: 20px;
    text-align: center;
}

.obj-heading-container{
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin-top: 30px;
}

.obj-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.obj-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    padding:10px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.obj-name {
    margin-bottom: 0 !important;
    font-size: 18px;
    margin-left: 10px;
    margin-right: 20px;
}

.icon-small {
    width: 30px;
    height : 30px;
    border-radius: 50%;
}

.obj-circle{
    height: 25px;
    width: 25px;
    background-color: #818181;
    border-radius: 50%;
    display: flex;
    color : white;
    justify-content: center;
    align-items: center;
}

.b-57{
    bottom: 57px !important;
}

.b-98{
    bottom: 98px !important;
}

.obj-card-design{
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg,white, var(--primary-color-light),var(--primary-color-dark));
    position: absolute;
    left: 0;
}

.obj-notification-icon{
    border-bottom: 1px solid white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: white;
}

.vh-90{
    height: 90vh;
}

.ml-240{
    margin-left: 240px ;
}

@media screen and (max-width : 700px) {
    .ml-240{
        margin-left: 0px !important;
    }
}

.slant {
    background: var(--primary-color-v2);
    color: var(--secondary-color-v2);
    position: relative;
    z-index: 1;
    margin-top: -1px;
    margin-bottom: 200px;
    height: 34vh;
}

  .slant:after {
    background: inherit;
    bottom: -100px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-3deg);
    transform-origin: 100%;
    z-index: -1;
  }

.slant .h1 {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

/* Fix/hack for 1080p+ displays
@media screen and (min-width: 1921px) {
    .slant {
      padding-bottom: 5px;
      margin-bottom: 240px !important;
    }

    .slant:after {
      bottom: -220px !important;
  }
} */