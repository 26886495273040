.reference-container{
    margin-right: 15px;
    min-width: 270px;
}

.reference-container:last-child{
    margin-right: 20px;
}

.reference-container:first-child{
    margin-left: 20px;
}

.status-container{
    width: 121px;
}

.h-180{
    height: 180px;
}

.w-60 {
    width: 60%;
}

.course-status{
    width: 100%;
    padding: 5px 8px;
    border: 1px solid black;
    border-radius: 4px;
    background: transparent
}

.course-content-three-line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}