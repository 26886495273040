.members-card{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0.75rem 1rem;
}

.member-icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.member-status{
    display: flex;
    margin-left: auto;
    padding: 10px;
}

.w-100percentage{
    width: 100%;
}