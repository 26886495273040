.customize-input-box{
    border: none;
    padding: 5px;
    width: 100%;
    font-size: 14px;
    font-family: 'Lato',sans-serif;
}

.customize-input-box:hover,
.customize-input-box:focus{
    border-color: white;
    outline: none;
}
