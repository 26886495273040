.member-detail{
    border-bottom: 1px solid gray;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.lh-13{
    line-height: 13px;
}

.member-detail:last-child{
    border-bottom: none !important;
    margin-bottom: 0px !important;
}