.button-danger{
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: rgb(199, 44, 5);
}

.p-relative {
    position: relative;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px !important;
}

.image-button{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 50%;
}

.preview-image-container {
    width: 120px;
    height: 120px;
}

.preview-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    border-radius: 50%;
}

.edit-icon-size {
    width: 24px;
    height : 24px; 
}

.authentication-card{
    padding: .75rem 2rem;
    margin-left: auto ;
    margin-right: auto;
    border: none !important;
    max-width: 1000px;
}

.heading{
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.mt-12{
    margin-top : 12px;
}
