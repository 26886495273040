.update-subscribe-container {
    margin-left: 20%;
    margin-right: auto;
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .update-subscribe-container{
        margin-left: auto;
        margin-right: auto;
    }
}