.controls{
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    max-width: 800px;
}

.profile-img{
    width: 49px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

/* @media screen and (max-width : 768px) {
    .controls{
        left: 30% !important;
    }
} */