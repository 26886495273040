.sub-objective-aggregate{
    border: none !important;
    margin: auto;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 0.75rem 1rem;
}

.sub-vcf-card:first-child{
    margin-left: 20px;
}

.sub-vcf-card{
    margin-right: 20px;
    margin-bottom: 10px;
}

.mw-280{
    min-width: 280px !important;
}

.h-250{
    height: 250px !important;
}