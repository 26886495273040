.learning-header{
    overflow: hidden;
    white-space: nowrap;
    width: 227px;
    text-overflow: ellipsis;
    font-size: 20px;
    align-self: center;
}

.learning-icon-container{
    width: 100px;
    height: 100px;
}

.learning-icon-preview{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}