.department-list{
    list-style: none;
    display: flex;
    align-items: center;
    width: 100%;
    /* border-bottom: 1px solid #dfdfdf; */
}

.department-button-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.department-list-container li:last-child {
    border-bottom: none;
}

.department-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}