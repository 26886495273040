.chart-container{
    margin: 10px;
}

.preview-graph-container{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 20px;
    /* background: rgba(0, 0, 0, 0.3); */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

.metric-name-container{
    background: rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 10px;
    bottom: 0px;
    border-top-right-radius: 10px;
}