@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@1,300&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body {
  height: 100vh;
}

:root{
  --primary-color : rgba(255,128,106,255);  /*orange*/
  --primary-text-color : rgba(68,68,68,255); /*black*/
  --secondary-color : rgba(156,155,185,255); /*blue with grey*/
  --secondary-color-light : rgb(204 204 222); /*grey*/
  --secondary-text-color : rgba(156,155,185,255); /*light grey and icon color*/
  --success-color : ;
  --danger-color : ;
  --primary-color-light : rgba(255,171,135,255); /*light orange*/
  --tertiary-color: rgb(195,178,170); /*brown*/
  --primary-color-dark : rgba(255,122,102,255); /*dark orange*/
  /*set two*/
  --primary-color-v2: #14213d; /*Dark Blue*/
  --primary-color-light-v2: #172647; /*Blue*/
  --primary-color-dark-v2: #0f182b; /*Darker blue*/;
  --accent-color-v2: rgb(252, 163, 17); /*Yellow*/
  --secondary-color-v2: #e5e5e5; /*Light Grey*/
  --tertiary-color-v2: #f9f9f9; /*Very light grey*/
  --text-color-dark-v2: #000000; /*Black*/ 
  --text-color-light-v2: #ffffff; /*White*/ 
  --primary-text-color-v2: #222222;
  --tertiary-text-color-v2: #5c5c5c;
  --secondary-text-color-v2: #858585;
}

@font-face {
  font-family: "segoe";
  src: url('./font/segoepr_0.ttf');
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.primary-color-v2{
  color: var(--primary-color-v2)
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.border-radius-20{
  border-radius: 20px;
}

.fs24-lh30{
  font-size: 24px;
  line-height: 30px;
}

/* lato font set*/
.lato-fs-42-normal-lh54{
  font-family: 'Lato', sans-serif;
  font-size: 42px;
  line-height: 54px;
  font-weight: 400;
}

.lato-fs36-normal-lh42{
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs34-normal-lh42{
  font-size: 34px;
  line-height: 42px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs26-normal-lh32{
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs26-bold-lh32{
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Lato",sans-serif;
}

.lato-fs24-bold-lh32 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Lato",sans-serif;
}

.lato-fs24-normal-lh32 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs22-normal-lh32{
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs-20-normal-lh32{
  font-size: 20px;
  line-height: 32px;
  font-family: 'Lato',sans-serif;
  font-weight: 400;
}

.lato-fs20-normal-lh28{
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.lato-fs19-normal-lh22{
  font-size: 19px;
  line-height: 22px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs18-normal-lh22{
  font-size: 18px;
  line-height: 22px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs18-bold-lh22{
  font-size: 18px;
  line-height: 22px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.lato-fs18-normal-lh25 {
  font-size: 18px;
  line-height: 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
}

.lato-fs17-normal-lh20 {
  font-size: 17px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs16-normal-lh20 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs15-normal-lh20 {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs15-light-lh20 {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.lato-fs14-normal-lh20 {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs13-normal-lh18 {
  font-size: 13px;
  line-height: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs12-normal-lh15 {
  font-size: 12px;
  line-height: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.opacity-50 {
  opacity: 0.5;
}

.pe-none {
  pointer-events: 'none';
}

.font-extra-bold {
  font-weight: 700 !important;
}

.font-very-extra-bold {
  font-weight: 800 !important;
}

.font-boldest {
  font-weight: 900 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-light{
  font-weight: 300;
}

.border-radius-1rem{
  border-radius: 1rem;
}

.mb-40{
  margin-bottom: 40px;
}

/*max width*/
.max-width-250px {
  max-width: 250px;
}

.max-width-350px {
  max-width: 350px;
}

.max-width-385px {
    max-width: 385px;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-600px {
  max-width: 600px;
}

.max-width-1000px {
  max-width: 1000px;
}

.max-width-1200px {
  max-width: 1200px;
}

.max-width-1400px {
  max-width: 1400px;
}

/*min width*/
.min-width-100px {
  min-width: 100px;
}

.min-width-200px{
  min-width: 200px;
}

.min-width-250px{
  min-width: 250px;
}

.min-width-300px{
  min-width: 300px;
}

.min-width-350px{
  min-width: 350px;
}


/*min height*/
.min-height-240px {
  min-height: 240px;
}

.fs-15{
  font-size: 15px;
}

.fs-18{
  font-size: 18px;
}

.pt-cursor{
  cursor: pointer;
}

.w-280{
  min-width: 280px;
}

.h-200{
  height: 200px;
}

.fs-20{
  font-size: 20px;
}

.fs-10{
  font-size: 10px;
}

.fs-17 {
  font-size: 17px;
}

.scroll-container{
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.overflow-auto{
  overflow: auto;
}

.tab-container-300px{
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.reference-card{
  padding: 1rem 0.75rem;
  flex-direction: row !important;
  min-width: 286px !important;
  margin-right: 10px;
}

.reference-bg-shadow{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background: white !important;
}

.breadcrumbs-link{
  color: black;
  text-decoration: none;
}

.breadcrumbs-link:hover{
  color: black;
  text-decoration: none;
}

.h-90vh{
  height: 90vh;
}

.h-85vh{
  height: 85vh ;
}

.h-71vh{
  height: 71vh !important;
}

.cursor-pt{
  cursor: pointer;
}

.overlay-popup-80vh{
  margin-top: calc(100vh - 80vh) !important;
}

.list-style-none{
  list-style: none !important;
}

/* error message */
.error-message {
  color: red;
  font-size: 10px;
  font-family: "Lato",sans-serif;
}

.profile{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.back-arrow{                 /*need to remove*/
  padding-bottom: 10px;
  width: 30px;
  margin-right: 5px;
  color: var(--secondary-color);
}

.back-arrow-icon{
  color : var(--secondary-color);
  margin-right: 5px;
}

.color-pale-green{
  color: #00B200;
}

.color-red{
  color : #FF5733;
}

.container-bg{
  background: linear-gradient(180deg, rgba(240,245,250,255), rgba(251,253,254,255));
}

.transparent-bg{
  background: transparent !important;
}

.bg-secondary-color-v2 {
  background-color: var(--secondary-color-v2);
}

.bg-tertiary-color-v2 {
  background-color: var(--tertiary-color-v2);
}

.primary-text-color {
  color: var(--primary-text-color-v2);
}

.tertiary-text-color-v2 {
  color: var(--tertiary-text-color-v2);
}

.secondary-text-color{
  color: var(--secondary-text-color-v2);
}

.fs22-lh32{
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Oxygen',sans-serif;
  font-style: none;
}

.fs20-lh32{
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'oxygen', sans-serif;
  font-style: none;
}

.fs18-lh20{
  font-size: 18px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fs18-lh25{
  font-size: 18px;
  line-height: 25px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fs14-lh25-os{
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  font-style: none;
  font-family: 'segoe'; 
}

.fs20-lh30-os{
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-style: none;
  font-family: 'segoe'; 
}

.fs14-lh18{
  line-height: 18px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
  font-size: 14px; 
}

.fs16-lh20{
  font-size: 16px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fs17-lh20 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fb18-lh25{
  font-size: 18px;
  line-height: 25px;
  font-family: 'oxygen', sans-serif;
  font-weight: 600;
  font-style: none;
}

.fsb17-lh32 {
  font-size: 17px;
  line-height: 32px;
  font-family: 'oxygen', sans-serif;
  font-weight: 600;
  font-style: none;
}

.font-bold{
  font-weight: 600;
}

.fs15-lh20{
  font-size: 15px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
  padding-bottom: 5px;
}

.fs15-lh20-em{
  font-size: 15px;
  line-height: 1.51em;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
  word-break: break-word;
}

.fs13-lh15{
  font-size: 13px;
  font-family: 'oxygen',sans-serif;
  line-height: 15px;
  font-weight: normal;
}

.fs16{
  font-size: 16px;
  font-family: 'oxygen',sans-serif;
}

.fs-13{
  font-size: 13px;
}

.fs-12{
  font-size: 12px;
  font-family: 'oxygen',sans-serif;
  font-weight: 600;
  padding-top: 2px;
  letter-spacing: 0.25px;
}

.fs12-lh15{
  font-size: 12px;
  font-family: 'oxygen',sans-serif;
  font-weight: 500;
  padding-top: 2px;
  line-height: 15px;
}

/* .primary-color{
  color : var(--primary-text-color);
} */

.acent-color{
  color: var(--accent-color-v2);
}

.primary-color-v2 {
  color : var(--primary-color-v2)
}

.secondary-color{
  color : var(--secondary-text-color)
}

.orange-color{
  color : var(--primary-color)
}

.white-color{
  color : white;
}

.ls-half{
  letter-spacing: 0.5px;
}

.ta-center{
  text-align: center;
}

.h-55vh{
  height: 55vh;
}

.h-72vh{
  height: 72vh;
}

.ml-10{
  margin-left: 10px;
}

.mr-10{
  margin-right: 10px;
}

.pr-20{
  padding-right: 20px;
}

.pl-20{
  padding-left: 20px;
}

.mb-4px{
  margin-bottom: 4px;
}

.minh-18_5{
  min-height: 18.5rem;
}

.flex-1{
  flex: 1;
}

.wh-15{
  width: 15px;
  height: 15px;
}

.aggregate-height{
  height: calc(100vh - 100px);
}

.no-action-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.two-line-wrap{
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.one-line-wrap{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex : 1;
  min-width: 0;
}

.white-bg{
  background: White !important;
}

/* button */

.reject-button{
  background-color: var(--secondary-color-light ) !important;
  font-family: 'oxygen', sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border: 1px solid var(--secondary-color-light) !important;
}

.reject-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(227 227 235) !important;
}

.accept-button{
  background-color: var(--primary-color) !important;
  font-family: 'oxygen', sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border: 1px solid var(--primary-color) !important;
}

.accept-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(255 171 156) !important;
}

.brown-color-button{
  background-color: var(--tertiary-color) !important;
  font-family: 'oxygen',sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border : 1px solid var(--tertiary-color) !important;
}

.brown-color-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(233 223 218) !important;
}

.blue-color-button{
  background-color: var(--secondary-color) !important;
  font-family: 'oxyygen',sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border : 1px solid var(--secondary-color) !important;
}

.blue-color-button:focus{
  box-shadow: 0 0 0 0.2rem var(--secondary-color-light) !important;
}

.drill-button{
  border-radius: 50% !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  width: 35px;
  height: 35px;
}

.drill-up-button{
  bottom: 98px !important;
  border-radius: 50% !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
}

.pt-16{
  padding-top: 16px !important;
}

/* image */

.icon-edit-button{
  position: absolute;
  bottom: 11px;
  right: 14px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 50%;
}

/* tab */

.tab-items{
  border: none !important;
  color: var(--secondary-text-color);
  font-weight: 700;
  font-size: 16px;
  font-family: 'oxygen',sans-serif;
  letter-spacing: 0.25px;
}

.tab-items.active{
  background: linear-gradient(90deg, var(--primary-color-light), var(--primary-color-dark)) !important;
  color: white !important;
  font-weight: 700;
  font-family: 'oxygen',sans-serif;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.tab-items:hover{
  color: var(--primary-text-color);
}

.border-tab.active{
  font-weight: 700;
  font-family: 'oxygen',sans-serif;
  border-bottom: 3px solid red !important;
  margin-bottom: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color : var(--primary-color) !important;
}

.border-tab{
  border: none !important;
  letter-spacing: 0.25px;
  color: var(--secondary-color-light);
  font-weight: 600;
  font-family: 'oxygen',sans-serif;
}

.border-tab:hover{
  color: var(--secondary-color-light);
}

/* reference file style */

.reference-filename{
  color: var(--primary-text-color);
  font-size: 17px;
  font-weight: normal;
  font-family: 'oxygen',sans-serif;
  line-height: 22px;
}

.reference-filename:hover{
  color: var(--primary-text-color);
  text-decoration: none;
}

.b-62{
  bottom: 62px !important;
}

.link{
  color: var(--primary-text-color-v2);
  text-decoration: none;
}

.link:hover{
  color: var(--primary-text-color-v2);
  text-decoration: none;
}

.h-53vh{
  height: 53vh;
}

/* Autocomplete */

.css-17vbkzs-MuiFormControl-root-MuiTextField-root{
  margin-bottom: 0px !important;
}

.pb-60px{
  padding-bottom: 60px;
}

.plan-card{
  padding: 10px 15px;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.bg-red {
  background-color: red !important; 
}

.bg-blue{
  background-color: rgb(18, 18, 129) !important;
}

.bg-green{
  background-color: green !important;
}

.bg-amber{
  background-color: #FFBF00 !important;
}

.bg-pale-red {     /* error message */
  background-color: rgba(225, 0, 0, 0.05) !important;
}

.bg-pale-blue{
  background-color: rgb(221, 242, 249) !important;
}

.bg-pale-green{
  background-color: rgb(152,251,152) !important;
}

.bg-pale-amber{
  background-color: rgb(255, 226, 139) !important;
}

.primary-bg{
  background: var(--primary-color-dark);
}

.sub-obj-icon{
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ml-20{
  margin-left: 20px;
}

.mr-20{
  margin-right: 20px;
}

.secondary-border-bottom{
  border-bottom: 1px solid var(--secondary-color);
}

.objective-aggregate-height {
  height: calc(100vh - 253px);
}

.vcf-aggregate-height-without-company-view {
  height: calc(100vh - 253px);
}


/*process aggregate height and its media query*/
.process-aggregate-height {
  height: calc(100vh - 296px);
}

.content-area-height {
  height: calc(100vh - 115px);
}

option:disabled{
  color: #9d9696;
}

/* smartphones, Android phones, landscape iPhone */
@media only screen and (max-width: 599px) {

  .process-aggregate-height {
    height: calc(100vh - 288px);
  }

  .content-area-height {
    height: calc(100vh - 107px);
  }
}

.drill-button-container-fixed{
  position: fixed !important;
  bottom : 64px !important
}

.cu-container{
  max-width: 800px;
  margin: auto;
}

@media (min-width: 768px){
  .container, .container-sm, .container-md {
    max-width: 900px !important;
  }

  .objective-aggregate-height{
    height: calc(100vh - 277px) !important;
  }

  .vcf-aggregate-height-without-company-view {
    height: calc(100vh - 277px) !important;
  }
}

@media (min-width: 576px) and (max-width: 767px){
  .container, .container-sm {
    max-width: 600px !important;
  }
}

@media (min-width: 992px){
  .objective-aggregate-height{
    height: calc(100vh - 204px) !important;
  }
  .vcf-aggregate-height-without-company-view {
    height: calc(100vh - 180px) !important;
  }
}