.overlay{
    background: rgba(0,0,0, 0.3);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    min-height: 150px;
}

.obj-detail{
    display: flex;
    align-items: flex-end;
    padding: 18px 18px;
    height: 100%;
    min-height: 150px;
}

.obj-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.obj-icon-mobile{
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.vcf-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    padding:10px;
    border-radius: 10px;
    margin-left: 10px;
    min-height: 150px;
}

.vcf-card:last-child{
    margin-right: 10px;
}

.vcf-card.selected {
    box-shadow: 3px 4px 8px 0 var(--primary-color);
}

.no-white-space{
    white-space: nowrap;
}

.action-icon{
    width: 18px;
    height: 18px;
}

.status-container-card{
    margin-top: -10px;
    margin-right: -7px;
}

.approved-icon{
    width: 23px;
    height: 23px;
}

.sharing-status{
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
    border-radius: 4px;
}

.reject-sharing{
    color : #e77250;
    border: 1px solid #e77250;
}

.accept-sharing{
    color : #7bd11f;
    border: 1px solid #7bd11f;
}

.modify-sharing{
    color: #dfb928;
    border: 1px solid #dfb928;
}

/* @media (max-width : 480px) {
    .vcf-card{
        max-width: 350px;
    }
} */