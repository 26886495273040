.branchPopup{
    max-width: 576px !important;
    margin-top: calc(100vh - 85vh) !important;
}

@media screen and (max-width : 700px) {
    .branchPopup{
        margin-top: calc(100vh - 90vh) !important;
    }  
}

.close-btn-right {
    position: absolute;
    right: 1em;
}