.text-field-static {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    color: var(--primary-text-color);
    border-radius: 4px;
    background-color: #f2f2f2;
}

.h-40{
    height: 40px;
}