.wallpaper-preview-container {
    width: 100%;
    max-width: 540px;
    height: auto;
    aspect-ratio: 9/5;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

/* .wallpaper-preview-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    
} */