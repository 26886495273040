.upload-image{
    border: 1px dashed var(--secondary-color);
    width: 100%;
    line-height: 50px;
    background-color: white;
    border-radius: 8px;
    font-size: 18px;
    color: var(--secondary-color);
    font-family: 'Lato',sans-serif;
}

.mobile-upload-image{
    border: 1px dashed var(--secondary-color);
    line-height: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 15px 10px;
    color: var(--secondary-color);
}