.video-grid-container-row{
    position: relative;
    /* display: grid; */
    grid-gap: 10px;
}

#videos{
    position: relative;
    height: 85vh;
    width: 100vw;
    margin: auto;
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    justify-items: center;
    align-items: center;
}

/* .controls p{
    padding: 10px;
    cursor: pointer;
    background: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
}

.controls p.on{
    background: #F7F7F7;
    color: #38373A;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
} */

.join{
    position: absolute;
    z-index: 1;
    width: 30vw;
    height: fit-content;
    height: -moz-max-content;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 75vw;
}

.join  input{
    padding: 15px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
    width: 80%;
    display: block;
    margin:  50px auto;
}

.join  button{
    min-width: 200px;
    padding: 12px 0;
    text-align: center;
    background-color: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.user-name{
    position: absolute;
    bottom: 0;
    color: white;
    left: 10px;
    font-size: 20px;
    margin-bottom: 0px;
}

.video-mute-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    left: -47%;
}