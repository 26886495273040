.vid-controls {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: end;
}

.vid-user-name {
    background-color: white;
    border-radius: 1rem;
    padding: .25rem 1rem;
    color: var(--primary-text-color-v2);
    max-width: 200px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}