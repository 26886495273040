.list-container li:last-child {
    border-bottom: none;
}

.branch-list{
    list-style: none;
    display: flex;
    width: 100%;
    padding-bottom: 10px;
}

.branch-address{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}