.desktop-wallpaper-overlay{
    /* background: rgba(0,0,0, 0.3); */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: absolute;
}

.obj-wallpaper-container{
    width: 100%;
    /* height: 250px; */
    position: relative;
    /* margin-top: 15px; */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
    height: auto;
    aspect-ratio: 9/5;
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
}

.process-detail-icon-container{
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: 100px;
    margin-top: -67px;
    position: relative;
}

.process-icon-detail{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

.process-name-container{
    height: 100%;
    display: flex;
    align-items: flex-end;
    color: white;
}