.sign-in-card{
    padding: .75rem 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: none !important;
    max-width: 500px;
}

.sign-in-card-container{
    margin-left: 5%;
    margin-right: 5%;
}

.align-end {
    text-align: end;
}

.sign-in-footer{
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.sign-in-container{
    height: 92vh;
}

@media screen and ( max-width : 600px ) {
    .sign-in-container{
        height: 88vh;
    }
}