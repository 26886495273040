.custom-list {
    list-style: none; /* Remove default bullets */
}

.custom-list li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--primary-color-v2); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 2em; /* Also needed for space (tweak if needed) */
    margin-left: -2em; /* Also needed for space (tweak if needed) */
}

.list-style-none{
    list-style: none;
}

.list-icon{
    width: 2.5em;
    margin-left: -2.5em;
    font-size: 19px;
}