.circle{
    height: 25px;
    width: 25px;
    background-color: #e6e2e4;
    border-radius: 50%;
    margin-left: auto;
}

.wallpaper-container{
    width: auto;
    height: 200px;
    position: relative;
}

.icon-container{
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
}

.progress-chip{
    display: inline-block;
    padding: 0 20px;
    height: 30px;
    font-size: 15px;
    font-family: 'oxygen',sans-serif;
    line-height: 30px;
    border-radius: 25px;
    background-color: #f1f1f1;
}

.sticky-div {
    background-color: green;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
}

.start {
    height: 100px;
}

.end {
    height: 500px;
}

.obj-wallpaper{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}