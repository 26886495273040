.bottom-navlink-one{
    color: #b0b0b0;
    text-decoration: none;
    flex: 1;
}

.bottom-navlink{
    color: white;
    text-decoration: none;
    flex: 1;
}

.bottom-navlink-one:hover{
    color: white;
    text-decoration: none;
}

.fs-30{
    font-size: 30px;
}

.active{
    color: white;
}

.goal-icon{
    width: 18px;
}

.primary-color-bg{
    background-color: var(--primary-color-v2) !important;
}