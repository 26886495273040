.goals-container{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    height: calc(100vh - 220px);
}

.goals-card{
    display: flex;
    flex-direction: row;
    background-position: center;
    background-size: cover;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    min-width: 286px !important;
}

.goals-desc{
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 0px !important;
    line-height: 20px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: black;
}

.goals-heading{
    font-size: 20px;
    font-weight: 600;
}

.goals-details-container{
    margin-top: 20px;
}

.w-250{
    width: 250px;
}

.goal-card-container:first-child{
    margin-left: 20px;
}

.max-width-200px{
    max-width: 200px;
}

/* .slick-track{
    margin-left: 0px;
    margin-right: 0px;
} */

/* .goal-card-container:last-child{
    margin-right: 20px;
} */