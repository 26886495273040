.notification-card{
    margin: auto;
    border-radius: 20px !important;
    padding: 0.75rem 0.5rem;
    margin-bottom: 10px;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}

.one-line-wrap{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex : 1;
    min-width: 0;
}

.compose-button{
    position: absolute !important;
    right: 20px;
    bottom: 10px;
}

.tab-height{
    height: calc(100vh - 180px);
}

.main-tab-height{
    height: calc(100vh - 164px);
    position: relative;
}

.inner-tab-height{
    height: calc(100vh - 213px);
    overflow: auto;
}

.inner-tab-height-with-search{
    height: calc(100vh - 252px );
    overflow: auto;
}

.reference-height-with-search{
    overflow: auto;
    height: calc(100vh - 203px);
}

.desktop-main-tab-height{
    overflow: auto;
    height: calc(100vh - 119px);
}

.desktop-inner-tab-height{
    height: calc(100vh - 178px);
    overflow: auto;
}

.desktop-reference-height{
    height: calc(100vh - 114px);
    overflow: auto;
}

.compose-button-desktop {
    position: fixed !important;
    right: 5%;
    bottom: 64px;
}

.notification-desktop-tab {
    position: sticky;
    top: 60px;
}

.flex-none{
    flex: none;
}

.overflow-unset{
    overflow: unset;
}

.desktop-notification-tab-panel{
    margin-top: 39px;
    z-index: 1;
}

.desktop-compose-button{
    position: fixed !important;
    background-color: var(--primary-color-v2) !important;
    width: 40px;
    height: 40px;
    right: 5%;
    bottom: 15px;
    border-radius: 50% !important;

}

.content-area-height-not-and-ref {
    height: calc(100vh - 212px);
}

@media screen and (max-width: 599px) {
    .content-area-height-not-and-ref {
        height: calc(100vh - 204px);
    }

    .notification-subject {
        width: 150px;
    }
}