.profile-card{
    padding: 10px;
    border-radius: 10px!important;
}

.small-hl{
    margin: 1.5rem 15% !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.left-aligned-hl {
    margin: 1.5rem 60% 1.5rem 0 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.two-col-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.education-container:last-child > hr:last-child{
    display: none;
}

.qualification-container:last-child > hr:last-child{
    display: none;
}

.hl-long{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; 
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}