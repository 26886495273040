.pie-container {
    height: 300px;
    width: 300px;
    position: relative;
    /* overflow: hidden; */
}

.learning-tab{
    background: white;
    margin-left: 10px;
    margin-right: 10px;
}

#segment1-container .pie {
    background-color: #f3a886;
    transform:rotate(120deg);
}

#segment2-container,
#segment5-container {
    transform:rotate(120deg);
}

#segment2-container .pie {
    background-color: #b0bef1;
    transform:rotate(120deg);
}

#segment3-container,
#segment6-container {
    transform:rotate(240deg);
}

#segment3-container .pie {
    background-color: #a0ecb3;
    transform:rotate(120deg);
}

#segment4-container .pie {
    background-color: rgb(255, 255, 199);
    transform:rotate(120deg);
}

#segment5-container .pie {
    background-color: rgb(245, 191, 217);
    transform:rotate(120deg);
}

#segment6-container .pie {
    background-color: rgb(255, 127, 127);
    transform:rotate(120deg);
}

.hold {
    position:absolute;
    width:300px;
    height:300px;
    clip:rect(0px,300px,300px,150px);
    overflow: hidden;
}

.full-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(360deg);
    background-color: rgb(255, 255, 199);
}

.pie {
    position:absolute;
    width:300px;
    height:300px;
    clip:rect(0px, 150px, 300px, 0px);
    border-radius:150px;
}

.slice1-heading{
    transform: rotate( 240deg );
    width: 100px;
    height: 130px;
    margin-top: 30px;
    margin-left: 35px;
    padding: 5px;
    cursor: pointer;
}

.slice2-heading{
    transform: rotate( 120deg );
    width: 150px;
    height: 100px;
    margin-top: 60px;
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
}

.slice3-heading{
    width: 100px;
    height: 120px;
    margin-top: 50px;
    margin-left: 20px;
    padding: 5px;
    cursor: pointer;
}

.inner-circle{
    height: 60px;
    width: 60px;
    background-color: #e6e2e4;
    border-radius: 50%;
    margin-left: auto;
    top: 41%;
    position: absolute;
    left: 40%;
}

.full-width {
    width: 100%;
    height: 100%;
}

#semi-circle-left {
    transform: rotate(180deg);
}

#semi-circle-left .pie {
    transform: rotate(180deg);
    background-color: rgb(245, 191, 217);
}

#semi-circle-right .pie {
    transform: rotate(180deg);
    background-color: rgb(255, 255, 199);
}

#semi-circle-right {
    transform: rotate(360deg);
}

.arrow-circle {
    position: relative;
    display: flex;
    align-items: center ;
    justify-content: center;
    width: 330px;
    height: 330px;
    background-color: transparent;
    border-radius: 50%;
}

/* .active {
    color: black !important;
} */

.color-black{
    color: black !important;
}

.icon-preview-learning{
    margin-right: auto;
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    display: block;
    margin-left: auto;
}

.icon-preview-container-learning{
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 3rem;
    margin-left: 29px;
    transform: rotate( 239deg );
}

.segment2{
    transform: rotate( 122deg );
}

.segment3{
    transform: rotate( 360deg );
    margin-top: 4rem;
}

.button-z-index {
    z-index: 500;
}

.two-line-overflow-hidden{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.clickable-area-semi-right {
    margin-top : 50px;
    margin-left : 40px;
    width : 110px;
    height : 200px;
    transform: rotate(180deg);
    padding: 5px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.clickable-area-semi-left {
    margin-top : 50px;
    margin-left : 40px;
    width : 110px;
    height : 200px;
    padding: 5px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.clickable-area-full {
    width : 210px;
    height: 210px;
    cursor: pointer;
}

.table thead th {
    vertical-align: middle !important;
}

.tab-font{
    font-size: 13px !important;
}

.transparent-bg{
    background: transparent !important;
}

.course-container{
    margin-right: 20px;
}

.course-container:first-child{
    margin-left: 20px;
}

.learning-aggregate-height{
    height: calc(100vh - 367px);
}

.desktop-learnings-aggregate-height{
    height: calc(100vh - 276px) !important;
    overflow: auto;
}

.overflowY-hidden{
    overflow-y: hidden;
}

.overflow-unset{
    overflow: unset !important;
}

.content-area-min-height-desktop {
    min-height: calc(100vh - 450px);
}

.pagination .page-item.active .page-link{
    background-color: var(--primary-color-v2);
    border-color: var(--primary-color-dark-v2);
}

.pagination .page-item .page-link{
    color: var(--primary-color-v2);
}