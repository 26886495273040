.breadcrumb-new{
    display: inline-block;
    overflow: hidden;
    border-radius: 4px;
}

.breadcrumb-new a {
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-size: 14px;
    font-family: 'Lato',sans-serif;
    line-height: 20px;
    color: var(--text-color-dark-v2);
    padding: 4px 6px 4px 20px;
    background: var(--tertiary-color-v2);
    position: relative;
    transition: all 0.5s;
    margin-bottom: 5px;
}

.breadcrumb-new a.bg-white {
    background: white !important;
}

.breadcrumb-new a:first-child{
    padding-left: 14px;
    border-radius: 4px 0 0 4px;
}

.breadcrumb-new a:first-child::before{
    left: 14px;
}

.breadcrumb-new a:before{
    background: var(--tertiary-color-v2);
    /* box-shadow: 0 0 0 1px #ccc; */
}

.breadcrumb-new a.bg-white:before{
    background: white !important;
    /* box-shadow: 0 0 0 1px #ccc; */
}

.breadcrumb-new a:last-child{
    border-radius: 0 4px 4px 0;
    padding-right: 20px;
}

.breadcrumb-new a.b-active {
    background: linear-gradient(90deg, var(--primary-color-light-v2), var(--primary-color-v2)) !important;
    text-decoration: none;
    color : white;
    font-weight: 600;
}

.breadcrumb-new a:hover{
    /* background: var(--primary-color-dark); */
    text-decoration: none;
    color: inherit;
    /* color : var(--primary-color-v2); */
    /* font-weight: 600; */
}

.breadcrumb-new a.b-active:after{
    background: var(--primary-color-light);
}

.breadcrumb-new a.b-active:hover{
    color: white !important;
    text-decoration: none !important;
}

/* .breadcrumb-new a:hover:after{
    background: var(--primary-color-dark);
} */

.breadcrumb-new a:after{
    content: "";
    position: absolute;
    top: 0;
    right: -14px;
    width: 28px;
    height: 26px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    box-shadow: 2px -2px 0 1px rgba(0, 0, 0, 0.4), 3px -3px 0 2px rgb(255 255 255 / 10%);
    border-radius: 0 5px 0 50px;
    background : var(--tertiary-color-v2);
    color: var(--primary-text-color);
    transition: all 0.5s;
}

.breadcrumb-new a.bg-white:after{
    background : white !important;
}

.breadcrumb-new a:last-child:after{
    content : none;
}

.breadcrumb-new-img{
    width: 17px;
    height: 17px;
    margin-top: -4px;
}

.one-line-ellipsis{
    display: block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}