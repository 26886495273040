.member-list-container{
    /* margin-right: 210px; */
    padding-top: 25px;
}

.member-list-style{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 5px;
}

.member-list-style:last-child{
    border-bottom: none !important;
}

.icon-button{
    display: flex !important;
    justify-content: flex-end;
}

@media screen and (max-width : 600px) {
    .member-list-container{
        margin-right: 0px !important;
        padding-top: 0px !important;
    }
}