.popup{
    position: fixed;
    background: rgba(0,0,0,0.1);
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
    z-index: 1000;
}

.popup-container{
    position: relative;
    max-width: 416px;
    margin: 0 auto;
    background: #fff;
    max-height: 85vh;
    margin-top: calc(100vh - 70vh);
    border-radius: 8px;
    padding:20px;
    overflow: auto;
    box-sizing: border-box;
}

@media screen and (max-width:500px) {
    .popup-container{
        margin-left: 10px;
        margin-right: 10px;
    }
}