.input-label.Mui-focused{
    color: var(--primary-color-v2) !important;
}

.input-label{
    font-size: 15px !important;
    font-family: 'Lato',sans-serif !important;
}

.group-select{
    font-size: 15px !important;
    font-family: 'Lato',sans-serif;
}

.group-select.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--primary-color-v2) !important;
}