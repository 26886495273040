
.graph-card-rectangle{
    width: 17rem !important;
    height: 9rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.graph-card-rectangle.selected{
    box-shadow: 3px 4px 8px 0 var(--primary-color);
}

.graph-card-rectangle:first-child{
    margin-left: 10px;
}

.metric-container{
    margin-right: 20px;
}

.metric-container:first-child{
    margin-left: 20px;
}

.metric-container:last-child{
    margin-right: 20px;
}

.desktop-metric-aggregate-height{
    height: calc(100vh - 308px);
    overflow: auto;
    margin-top: 10px;
}

.desktop-no-action-metric{
    height: calc(100vh - 260px);
}

.height-1_5rem{
    height: 1.5rem !important;
}

.font-size-16px{
    font-size: 16px !important;
}