.desktop-nav-items{
    margin-right: 10px !important;
    font-size: 14px !important;
    font-family: "oxygen",sans-serif;
    cursor: pointer;
    color : whitesmoke;
}

.desktop-nav-items-selected{
    font-weight: 700 !important;
    font-size: 17px !important;
    color : white;
}

.process-navlink {
    text-decoration: none;
    flex: 1;
}

.normal-icon{
    color: #f5f5f5;
    font-size: 18px;
}

.normal-goal-icon{
    width: 20px;
    height: 20px;
}

.active-icon{
    color: rgb(255, 255, 255);
    font-size: 18px;
}

.process-icon-wrapper{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
}

.process-icon-wrapper:hover{
    background-color: rgba(0, 0, 0, 0.04);
}

.process-icon-wrapper.active{
    background-color: rgba(0, 0, 0, 0.04);
}