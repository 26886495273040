@import url(https://fonts.googleapis.com/css2?family=Oxygen&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@1,300&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap'); */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body {
  height: 100vh;
}

:root{
  --primary-color : rgba(255,128,106,255);  /*orange*/
  --primary-text-color : rgba(68,68,68,255); /*black*/
  --secondary-color : rgba(156,155,185,255); /*blue with grey*/
  --secondary-color-light : rgb(204 204 222); /*grey*/
  --secondary-text-color : rgba(156,155,185,255); /*light grey and icon color*/
  --success-color : ;
  --danger-color : ;
  --primary-color-light : rgba(255,171,135,255); /*light orange*/
  --tertiary-color: rgb(195,178,170); /*brown*/
  --primary-color-dark : rgba(255,122,102,255); /*dark orange*/
  /*set two*/
  --primary-color-v2: #14213d; /*Dark Blue*/
  --primary-color-light-v2: #172647; /*Blue*/
  --primary-color-dark-v2: #0f182b; /*Darker blue*/;
  --accent-color-v2: rgb(252, 163, 17); /*Yellow*/
  --secondary-color-v2: #e5e5e5; /*Light Grey*/
  --tertiary-color-v2: #f9f9f9; /*Very light grey*/
  --text-color-dark-v2: #000000; /*Black*/ 
  --text-color-light-v2: #ffffff; /*White*/ 
  --primary-text-color-v2: #222222;
  --tertiary-text-color-v2: #5c5c5c;
  --secondary-text-color-v2: #858585;
}

@font-face {
  font-family: "segoe";
  src: url(/static/media/segoepr_0.74c70684.ttf);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.primary-color-v2{
  color: #14213d;
  color: var(--primary-color-v2)
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.border-radius-20{
  border-radius: 20px;
}

.fs24-lh30{
  font-size: 24px;
  line-height: 30px;
}

/* lato font set*/
.lato-fs-42-normal-lh54{
  font-family: 'Lato', sans-serif;
  font-size: 42px;
  line-height: 54px;
  font-weight: 400;
}

.lato-fs36-normal-lh42{
  font-size: 36px;
  line-height: 42px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs34-normal-lh42{
  font-size: 34px;
  line-height: 42px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs26-normal-lh32{
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs26-bold-lh32{
  font-size: 26px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Lato",sans-serif;
}

.lato-fs24-bold-lh32 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Lato",sans-serif;
}

.lato-fs24-normal-lh32 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs22-normal-lh32{
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Lato",sans-serif;
}

.lato-fs-20-normal-lh32{
  font-size: 20px;
  line-height: 32px;
  font-family: 'Lato',sans-serif;
  font-weight: 400;
}

.lato-fs20-normal-lh28{
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.lato-fs19-normal-lh22{
  font-size: 19px;
  line-height: 22px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs18-normal-lh22{
  font-size: 18px;
  line-height: 22px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs18-bold-lh22{
  font-size: 18px;
  line-height: 22px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.lato-fs18-normal-lh25 {
  font-size: 18px;
  line-height: 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
}

.lato-fs17-normal-lh20 {
  font-size: 17px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs16-normal-lh20 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs15-normal-lh20 {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs15-light-lh20 {
  font-size: 15px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.lato-fs14-normal-lh20 {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs13-normal-lh18 {
  font-size: 13px;
  line-height: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.lato-fs12-normal-lh15 {
  font-size: 12px;
  line-height: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

.opacity-50 {
  opacity: 0.5;
}

.pe-none {
  pointer-events: 'none';
}

.font-extra-bold {
  font-weight: 700 !important;
}

.font-very-extra-bold {
  font-weight: 800 !important;
}

.font-boldest {
  font-weight: 900 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-light{
  font-weight: 300;
}

.border-radius-1rem{
  border-radius: 1rem;
}

.mb-40{
  margin-bottom: 40px;
}

/*max width*/
.max-width-250px {
  max-width: 250px;
}

.max-width-350px {
  max-width: 350px;
}

.max-width-385px {
    max-width: 385px;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-600px {
  max-width: 600px;
}

.max-width-1000px {
  max-width: 1000px;
}

.max-width-1200px {
  max-width: 1200px;
}

.max-width-1400px {
  max-width: 1400px;
}

/*min width*/
.min-width-100px {
  min-width: 100px;
}

.min-width-200px{
  min-width: 200px;
}

.min-width-250px{
  min-width: 250px;
}

.min-width-300px{
  min-width: 300px;
}

.min-width-350px{
  min-width: 350px;
}


/*min height*/
.min-height-240px {
  min-height: 240px;
}

.fs-15{
  font-size: 15px;
}

.fs-18{
  font-size: 18px;
}

.pt-cursor{
  cursor: pointer;
}

.w-280{
  min-width: 280px;
}

.h-200{
  height: 200px;
}

.fs-20{
  font-size: 20px;
}

.fs-10{
  font-size: 10px;
}

.fs-17 {
  font-size: 17px;
}

.scroll-container{
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.overflow-auto{
  overflow: auto;
}

.tab-container-300px{
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.reference-card{
  padding: 1rem 0.75rem;
  flex-direction: row !important;
  min-width: 286px !important;
  margin-right: 10px;
}

.reference-bg-shadow{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background: white !important;
}

.breadcrumbs-link{
  color: black;
  text-decoration: none;
}

.breadcrumbs-link:hover{
  color: black;
  text-decoration: none;
}

.h-90vh{
  height: 90vh;
}

.h-85vh{
  height: 85vh ;
}

.h-71vh{
  height: 71vh !important;
}

.cursor-pt{
  cursor: pointer;
}

.overlay-popup-80vh{
  margin-top: calc(100vh - 80vh) !important;
}

.list-style-none{
  list-style: none !important;
}

/* error message */
.error-message {
  color: red;
  font-size: 10px;
  font-family: "Lato",sans-serif;
}

.profile{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.back-arrow{                 /*need to remove*/
  padding-bottom: 10px;
  width: 30px;
  margin-right: 5px;
  color: rgba(156,155,185,255);
  color: var(--secondary-color);
}

.back-arrow-icon{
  color : rgba(156,155,185,255);
  color : var(--secondary-color);
  margin-right: 5px;
}

.color-pale-green{
  color: #00B200;
}

.color-red{
  color : #FF5733;
}

.container-bg{
  background: linear-gradient(180deg, rgba(240,245,250,255), rgba(251,253,254,255));
}

.transparent-bg{
  background: transparent !important;
}

.bg-secondary-color-v2 {
  background-color: #e5e5e5;
  background-color: var(--secondary-color-v2);
}

.bg-tertiary-color-v2 {
  background-color: #f9f9f9;
  background-color: var(--tertiary-color-v2);
}

.primary-text-color {
  color: #222222;
  color: var(--primary-text-color-v2);
}

.tertiary-text-color-v2 {
  color: #5c5c5c;
  color: var(--tertiary-text-color-v2);
}

.secondary-text-color{
  color: #858585;
  color: var(--secondary-text-color-v2);
}

.fs22-lh32{
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Oxygen',sans-serif;
  font-style: none;
}

.fs20-lh32{
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'oxygen', sans-serif;
  font-style: none;
}

.fs18-lh20{
  font-size: 18px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fs18-lh25{
  font-size: 18px;
  line-height: 25px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fs14-lh25-os{
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  font-style: none;
  font-family: 'segoe'; 
}

.fs20-lh30-os{
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-style: none;
  font-family: 'segoe'; 
}

.fs14-lh18{
  line-height: 18px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
  font-size: 14px; 
}

.fs16-lh20{
  font-size: 16px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fs17-lh20 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
}

.fb18-lh25{
  font-size: 18px;
  line-height: 25px;
  font-family: 'oxygen', sans-serif;
  font-weight: 600;
  font-style: none;
}

.fsb17-lh32 {
  font-size: 17px;
  line-height: 32px;
  font-family: 'oxygen', sans-serif;
  font-weight: 600;
  font-style: none;
}

.font-bold{
  font-weight: 600;
}

.fs15-lh20{
  font-size: 15px;
  line-height: 20px;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
  padding-bottom: 5px;
}

.fs15-lh20-em{
  font-size: 15px;
  line-height: 1.51em;
  font-family: 'oxygen', sans-serif;
  font-weight: 500;
  font-style: none;
  word-break: break-word;
}

.fs13-lh15{
  font-size: 13px;
  font-family: 'oxygen',sans-serif;
  line-height: 15px;
  font-weight: normal;
}

.fs16{
  font-size: 16px;
  font-family: 'oxygen',sans-serif;
}

.fs-13{
  font-size: 13px;
}

.fs-12{
  font-size: 12px;
  font-family: 'oxygen',sans-serif;
  font-weight: 600;
  padding-top: 2px;
  letter-spacing: 0.25px;
}

.fs12-lh15{
  font-size: 12px;
  font-family: 'oxygen',sans-serif;
  font-weight: 500;
  padding-top: 2px;
  line-height: 15px;
}

/* .primary-color{
  color : var(--primary-text-color);
} */

.acent-color{
  color: rgb(252, 163, 17);
  color: var(--accent-color-v2);
}

.primary-color-v2 {
  color : #14213d;
  color : var(--primary-color-v2)
}

.secondary-color{
  color : rgba(156,155,185,255);
  color : var(--secondary-text-color)
}

.orange-color{
  color : rgba(255,128,106,255);
  color : var(--primary-color)
}

.white-color{
  color : white;
}

.ls-half{
  letter-spacing: 0.5px;
}

.ta-center{
  text-align: center;
}

.h-55vh{
  height: 55vh;
}

.h-72vh{
  height: 72vh;
}

.ml-10{
  margin-left: 10px;
}

.mr-10{
  margin-right: 10px;
}

.pr-20{
  padding-right: 20px;
}

.pl-20{
  padding-left: 20px;
}

.mb-4px{
  margin-bottom: 4px;
}

.minh-18_5{
  min-height: 18.5rem;
}

.flex-1{
  flex: 1 1;
}

.wh-15{
  width: 15px;
  height: 15px;
}

.aggregate-height{
  height: calc(100vh - 100px);
}

.no-action-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.two-line-wrap{
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.one-line-wrap{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex : 1 1;
  min-width: 0;
}

.white-bg{
  background: White !important;
}

/* button */

.reject-button{
  background-color: rgb(204 204 222) !important;
  background-color: var(--secondary-color-light ) !important;
  font-family: 'oxygen', sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border: 1px solid rgb(204 204 222) !important;
  border: 1px solid var(--secondary-color-light) !important;
}

.reject-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(227 227 235) !important;
}

.accept-button{
  background-color: rgba(255,128,106,255) !important;
  background-color: var(--primary-color) !important;
  font-family: 'oxygen', sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border: 1px solid rgba(255,128,106,255) !important;
  border: 1px solid var(--primary-color) !important;
}

.accept-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(255 171 156) !important;
}

.brown-color-button{
  background-color: rgb(195,178,170) !important;
  background-color: var(--tertiary-color) !important;
  font-family: 'oxygen',sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border : 1px solid rgb(195,178,170) !important;
  border : 1px solid var(--tertiary-color) !important;
}

.brown-color-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(233 223 218) !important;
}

.blue-color-button{
  background-color: rgba(156,155,185,255) !important;
  background-color: var(--secondary-color) !important;
  font-family: 'oxyygen',sans-serif;
  font-weight: 600 !important;
  color: white !important;
  border : 1px solid rgba(156,155,185,255) !important;
  border : 1px solid var(--secondary-color) !important;
}

.blue-color-button:focus{
  box-shadow: 0 0 0 0.2rem rgb(204 204 222) !important;
  box-shadow: 0 0 0 0.2rem var(--secondary-color-light) !important;
}

.drill-button{
  border-radius: 50% !important;
  background-color: rgba(156,155,185,255) !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid rgba(156,155,185,255) !important;
  border: 1px solid var(--secondary-color) !important;
  width: 35px;
  height: 35px;
}

.drill-up-button{
  bottom: 98px !important;
  border-radius: 50% !important;
  background-color: rgba(156,155,185,255) !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid rgba(156,155,185,255) !important;
  border: 1px solid var(--secondary-color) !important;
}

.pt-16{
  padding-top: 16px !important;
}

/* image */

.icon-edit-button{
  position: absolute;
  bottom: 11px;
  right: 14px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  border-radius: 50%;
}

/* tab */

.tab-items{
  border: none !important;
  color: rgba(156,155,185,255);
  color: var(--secondary-text-color);
  font-weight: 700;
  font-size: 16px;
  font-family: 'oxygen',sans-serif;
  letter-spacing: 0.25px;
}

.tab-items.active{
  background: linear-gradient(90deg, rgba(255,171,135,255), rgba(255,122,102,255)) !important;
  background: linear-gradient(90deg, var(--primary-color-light), var(--primary-color-dark)) !important;
  color: white !important;
  font-weight: 700;
  font-family: 'oxygen',sans-serif;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.tab-items:hover{
  color: rgba(68,68,68,255);
  color: var(--primary-text-color);
}

.border-tab.active{
  font-weight: 700;
  font-family: 'oxygen',sans-serif;
  border-bottom: 3px solid red !important;
  margin-bottom: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  color : rgba(255,128,106,255) !important;
  color : var(--primary-color) !important;
}

.border-tab{
  border: none !important;
  letter-spacing: 0.25px;
  color: rgb(204 204 222);
  color: var(--secondary-color-light);
  font-weight: 600;
  font-family: 'oxygen',sans-serif;
}

.border-tab:hover{
  color: rgb(204 204 222);
  color: var(--secondary-color-light);
}

/* reference file style */

.reference-filename{
  color: rgba(68,68,68,255);
  color: var(--primary-text-color);
  font-size: 17px;
  font-weight: normal;
  font-family: 'oxygen',sans-serif;
  line-height: 22px;
}

.reference-filename:hover{
  color: rgba(68,68,68,255);
  color: var(--primary-text-color);
  text-decoration: none;
}

.b-62{
  bottom: 62px !important;
}

.link{
  color: #222222;
  color: var(--primary-text-color-v2);
  text-decoration: none;
}

.link:hover{
  color: #222222;
  color: var(--primary-text-color-v2);
  text-decoration: none;
}

.h-53vh{
  height: 53vh;
}

/* Autocomplete */

.css-17vbkzs-MuiFormControl-root-MuiTextField-root{
  margin-bottom: 0px !important;
}

.pb-60px{
  padding-bottom: 60px;
}

.plan-card{
  padding: 10px 15px;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.bg-red {
  background-color: red !important; 
}

.bg-blue{
  background-color: rgb(18, 18, 129) !important;
}

.bg-green{
  background-color: green !important;
}

.bg-amber{
  background-color: #FFBF00 !important;
}

.bg-pale-red {     /* error message */
  background-color: rgba(225, 0, 0, 0.05) !important;
}

.bg-pale-blue{
  background-color: rgb(221, 242, 249) !important;
}

.bg-pale-green{
  background-color: rgb(152,251,152) !important;
}

.bg-pale-amber{
  background-color: rgb(255, 226, 139) !important;
}

.primary-bg{
  background: rgba(255,122,102,255);
  background: var(--primary-color-dark);
}

.sub-obj-icon{
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.ml-20{
  margin-left: 20px;
}

.mr-20{
  margin-right: 20px;
}

.secondary-border-bottom{
  border-bottom: 1px solid rgba(156,155,185,255);
  border-bottom: 1px solid var(--secondary-color);
}

.objective-aggregate-height {
  height: calc(100vh - 253px);
}

.vcf-aggregate-height-without-company-view {
  height: calc(100vh - 253px);
}


/*process aggregate height and its media query*/
.process-aggregate-height {
  height: calc(100vh - 296px);
}

.content-area-height {
  height: calc(100vh - 115px);
}

option:disabled{
  color: #9d9696;
}

/* smartphones, Android phones, landscape iPhone */
@media only screen and (max-width: 599px) {

  .process-aggregate-height {
    height: calc(100vh - 288px);
  }

  .content-area-height {
    height: calc(100vh - 107px);
  }
}

.drill-button-container-fixed{
  position: fixed !important;
  bottom : 64px !important
}

.cu-container{
  max-width: 800px;
  margin: auto;
}

@media (min-width: 768px){
  .container, .container-sm, .container-md {
    max-width: 900px !important;
  }

  .objective-aggregate-height{
    height: calc(100vh - 277px) !important;
  }

  .vcf-aggregate-height-without-company-view {
    height: calc(100vh - 277px) !important;
  }
}

@media (min-width: 576px) and (max-width: 767px){
  .container, .container-sm {
    max-width: 600px !important;
  }
}

@media (min-width: 992px){
  .objective-aggregate-height{
    height: calc(100vh - 204px) !important;
  }
  .vcf-aggregate-height-without-company-view {
    height: calc(100vh - 180px) !important;
  }
}
.button-danger{
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: rgb(199, 44, 5);
}

.p-relative {
    position: relative;
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px !important;
}

.image-button{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 50%;
}

.preview-image-container {
    width: 120px;
    height: 120px;
}

.preview-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    border-radius: 50%;
}

.edit-icon-size {
    width: 24px;
    height : 24px; 
}

.authentication-card{
    padding: .75rem 2rem;
    margin-left: auto ;
    margin-right: auto;
    border: none !important;
    max-width: 1000px;
}

.heading{
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.mt-12{
    margin-top : 12px;
}

.grid-column-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.corporate-profile-grid{
    margin-top: 12px;
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 12px;
    row-gap: 12px;
}

@media screen and (max-width : 700px) {
    .grid-column-2{
        grid-template-columns: none;
    }

    .corporate-profile-grid{
        grid-template-columns: 1fr 1fr;
    }
}
/* .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color:  var(--secondary-text-color) !important;
} */
.profile-card{
    padding: 10px;
    border-radius: 10px!important;
}

.small-hl{
    margin: 1.5rem 15% !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.left-aligned-hl {
    margin: 1.5rem 60% 1.5rem 0 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.two-col-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.education-container:last-child > hr:last-child{
    display: none;
}

.qualification-container:last-child > hr:last-child{
    display: none;
}

.hl-long{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; 
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.desktop-nav-items{
    margin-right: 10px !important;
    font-size: 14px !important;
    font-family: "oxygen",sans-serif;
    cursor: pointer;
    color : whitesmoke;
}

.desktop-nav-items-selected{
    font-weight: 700 !important;
    font-size: 17px !important;
    color : white;
}

.process-navlink {
    text-decoration: none;
    flex: 1 1;
}

.normal-icon{
    color: #f5f5f5;
    font-size: 18px;
}

.normal-goal-icon{
    width: 20px;
    height: 20px;
}

.active-icon{
    color: rgb(255, 255, 255);
    font-size: 18px;
}

.process-icon-wrapper{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
}

.process-icon-wrapper:hover{
    background-color: rgba(0, 0, 0, 0.04);
}

.process-icon-wrapper.active{
    background-color: rgba(0, 0, 0, 0.04);
}
.more-popup-container{
    margin-left: auto;
    margin-top: 66px !important;
    margin-right: 0px;
    max-width: 240px !important;
    padding: 0px !important;
}
.profile-chip:hover{
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 15px;
}

.profile-chip{
    padding: 3px;
}
.left-vertical-menu-bar {
    width: 70px;
    background-color: var(--primary-color-v2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed; 
    top: 0px; 
    bottom: 0px;
    /* z-index:"1111"; */
    margin-top: 64px;
}

.process-bg {
    background-color: var(--primary-color-dark-v2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.process-navlink {
    text-decoration: none;
}

.process-navlink.active {
    border-left: 3.5px solid white;
}
.sub-main-container{
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.bottom-navlink-one{
    color: #b0b0b0;
    text-decoration: none;
    flex: 1 1;
}

.bottom-navlink{
    color: white;
    text-decoration: none;
    flex: 1 1;
}

.bottom-navlink-one:hover{
    color: white;
    text-decoration: none;
}

.fs-30{
    font-size: 30px;
}

.active{
    color: white;
}

.goal-icon{
    width: 18px;
}

.primary-color-bg{
    background-color: var(--primary-color-v2) !important;
}
.button {
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    color: #000;
    border: none;
    border-radius: 5px;
    background-color: rgb(252, 221, 183);
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.label-heading{
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.required:after {
    content:" *";
    color: red;
}

.subscription-label{
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    margin-bottom: 0.5rem;
}

.textarea-container{
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    height: 70px;
    padding: 10px;
}

.authentication-card {
    padding: .75rem 2rem;
    margin-left: auto ;
    margin-right: auto;
    border: none !important;
    max-width: 1000px;
}

.auth-heading{
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.subscription-amount-container{
    display: flex;
    margin-left: 0.7rem;
}

.subscription-amount{
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
    display: flex;
    margin-bottom: 0.5rem;
    padding-left: 15px;
}
.input-box:focus{
    outline: none;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-color: Var(--secondary-color) !important;
} */
.input-label.Mui-focused{
    color: var(--primary-color-v2) !important;
}

.input-label{
    font-size: 15px !important;
    font-family: 'Lato',sans-serif !important;
}

.group-select{
    font-size: 15px !important;
    font-family: 'Lato',sans-serif;
}

.group-select.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--primary-color-v2) !important;
}
.width-150{
    width : 150px !important;
}

.grey-select-box{
    width: 100%;
    padding: 10px 5px;
    border: 1px solid var(--secondary-text-color);
    border-radius: 4px;
    color: var(--primary-text-color);
    font-family: 'Lato',sans-serif;
    background: transparent;
}

.grey-select-box:focus{
    outline: none;
    border : 2px solid var(--primary-color-v2);
}
.footer-container{
    color: white !important;
    display: flex !important;
    justify-content: space-evenly;
    min-height: 40px;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 8px;
    width: 86%;
    margin-left: auto;
    margin-right: auto;
}

.footer-link{
    text-align: center;
    margin-bottom: 0px !important;
    color: white !important;
    text-decoration: none;
    margin-right: 10px;
}

.footer-link:hover{
    text-decoration: none;
}

@media screen and (max-width : 660px) {
    .footer-container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-link{
        padding-bottom: 8px;
    }
}
.sign-in-card{
    padding: .75rem 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: none !important;
    max-width: 500px;
}

.sign-in-card-container{
    margin-left: 5%;
    margin-right: 5%;
}

.align-end {
    text-align: end;
}

.sign-in-footer{
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.sign-in-container{
    height: 92vh;
}

@media screen and ( max-width : 600px ) {
    .sign-in-container{
        height: 88vh;
    }
}
.float-button{
    position: fixed;
    overflow: hidden;
    right: 30px;
    bottom: 43px;
    text-align: center;
}

.img-card{
    min-height: 100px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    background: white !important;
    border-radius: 1.5rem !important;
}

.fs-20{                 /*need to remove*/
    font-size: 20px;
    text-align: center;
}

.obj-heading-container{
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin-top: 30px;
}

.obj-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.obj-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    padding:10px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.obj-name {
    margin-bottom: 0 !important;
    font-size: 18px;
    margin-left: 10px;
    margin-right: 20px;
}

.icon-small {
    width: 30px;
    height : 30px;
    border-radius: 50%;
}

.obj-circle{
    height: 25px;
    width: 25px;
    background-color: #818181;
    border-radius: 50%;
    display: flex;
    color : white;
    justify-content: center;
    align-items: center;
}

.b-57{
    bottom: 57px !important;
}

.b-98{
    bottom: 98px !important;
}

.obj-card-design{
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg,white, var(--primary-color-light),var(--primary-color-dark));
    position: absolute;
    left: 0;
}

.obj-notification-icon{
    border-bottom: 1px solid white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: white;
}

.vh-90{
    height: 90vh;
}

.ml-240{
    margin-left: 240px ;
}

@media screen and (max-width : 700px) {
    .ml-240{
        margin-left: 0px !important;
    }
}

.slant {
    background: var(--primary-color-v2);
    color: var(--secondary-color-v2);
    position: relative;
    z-index: 1;
    margin-top: -1px;
    margin-bottom: 200px;
    height: 34vh;
}

  .slant:after {
    background: inherit;
    bottom: -100px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: skewY(-3deg);
            transform: skewY(-3deg);
    -webkit-transform-origin: 100%;
            transform-origin: 100%;
    z-index: -1;
  }

.slant .h1 {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

/* Fix/hack for 1080p+ displays
@media screen and (min-width: 1921px) {
    .slant {
      padding-bottom: 5px;
      margin-bottom: 240px !important;
    }

    .slant:after {
      bottom: -220px !important;
  }
} */
.fs-6px{
    font-size: 6px;
}

.fs-8px{
    font-size: 8px;
}

.fs-10{
    font-size: 10px;
}

.secondary-color-light{
    color : rgb(197 197 205);
}

.mb-2px{
    margin-bottom: 2px;
}
.vcf-card-square-desktop.selected, .vcf-card-square.selected {
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.75);
}

.vcf-card-square-desktop {
    display: flex;
    /* height: 280px; */
    flex-direction: column;
}

.vcf-card-square {
    display: flex;
    /* height: 220px; */
    flex-direction: column;
}

.shared-status-container{
    position: absolute;
    right: 10px;
    top: 10px;
}

.cursor-unset{
    cursor: unset;
}

.no-icon-card-height{
    height: 250px !important;
}
.breadcrumb-new{
    display: inline-block;
    overflow: hidden;
    border-radius: 4px;
}

.breadcrumb-new a {
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-size: 14px;
    font-family: 'Lato',sans-serif;
    line-height: 20px;
    color: var(--text-color-dark-v2);
    padding: 4px 6px 4px 20px;
    background: var(--tertiary-color-v2);
    position: relative;
    transition: all 0.5s;
    margin-bottom: 5px;
}

.breadcrumb-new a.bg-white {
    background: white !important;
}

.breadcrumb-new a:first-child{
    padding-left: 14px;
    border-radius: 4px 0 0 4px;
}

.breadcrumb-new a:first-child::before{
    left: 14px;
}

.breadcrumb-new a:before{
    background: var(--tertiary-color-v2);
    /* box-shadow: 0 0 0 1px #ccc; */
}

.breadcrumb-new a.bg-white:before{
    background: white !important;
    /* box-shadow: 0 0 0 1px #ccc; */
}

.breadcrumb-new a:last-child{
    border-radius: 0 4px 4px 0;
    padding-right: 20px;
}

.breadcrumb-new a.b-active {
    background: linear-gradient(90deg, var(--primary-color-light-v2), var(--primary-color-v2)) !important;
    text-decoration: none;
    color : white;
    font-weight: 600;
}

.breadcrumb-new a:hover{
    /* background: var(--primary-color-dark); */
    text-decoration: none;
    color: inherit;
    /* color : var(--primary-color-v2); */
    /* font-weight: 600; */
}

.breadcrumb-new a.b-active:after{
    background: var(--primary-color-light);
}

.breadcrumb-new a.b-active:hover{
    color: white !important;
    text-decoration: none !important;
}

/* .breadcrumb-new a:hover:after{
    background: var(--primary-color-dark);
} */

.breadcrumb-new a:after{
    content: "";
    position: absolute;
    top: 0;
    right: -14px;
    width: 28px;
    height: 26px;
    -webkit-transform: scale(0.707) rotate(45deg);
            transform: scale(0.707) rotate(45deg);
    z-index: 1;
    box-shadow: 2px -2px 0 1px rgba(0, 0, 0, 0.4), 3px -3px 0 2px rgb(255 255 255 / 10%);
    border-radius: 0 5px 0 50px;
    background : var(--tertiary-color-v2);
    color: var(--primary-text-color);
    transition: all 0.5s;
}

.breadcrumb-new a.bg-white:after{
    background : white !important;
}

.breadcrumb-new a:last-child:after{
    content : none;
}

.breadcrumb-new-img{
    width: 17px;
    height: 17px;
    margin-top: -4px;
}

.one-line-ellipsis{
    display: block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sub-objective-aggregate{
    border: none !important;
    margin: auto;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 0.75rem 1rem;
}

.sub-vcf-card:first-child{
    margin-left: 20px;
}

.sub-vcf-card{
    margin-right: 20px;
    margin-bottom: 10px;
}

.mw-280{
    min-width: 280px !important;
}

.h-250{
    height: 250px !important;
}
.slick-track{
    margin-left: 0px;
    margin-right: 0px;
}
.frost {
    -webkit-backdrop-filter: blur(8px);  /* Safari 9+ */
    backdrop-filter: blur(8px); /* Chrome and Opera */
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
    background-color: rgb(228 228 228 / 15%);
}
.company-view-sidebar{
    min-width: 320px;
    max-width: 320px;
    border-radius: 10px;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
    background-color: var(--secondary-color-v2);
    height: 100%;
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
    /* -webkit-transition: right .3s ease-in-out; */
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%)
}

.sidebar-container {
    position: -webkit-sticky;
    position: sticky;
    top: 76px;
    min-height: calc(100vh - 365px);
    max-height: calc(100vh - 100px);
}

.company-view-sidebar-content {
    overflow-y: auto;
    max-height: calc(100vh - 157px);
}
.main-container {
    width: calc(100% - 70px); 
    min-height: calc(100vh - 64px); 
    margin-left: 70px;
}
.slant {
    background: var(--primary-color-v2);
    color: var(--secondary-color-v2);
    position: relative;
    z-index: 1;
    margin-top: -1px;
    margin-bottom: 200px;
    height: 34vh;
}

.slant:after {
    background: inherit;
    bottom: -100px;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: skewY(-3deg);
            transform: skewY(-3deg);
    -webkit-transform-origin: 100%;
            transform-origin: 100%;
    z-index: -1;
}

.slant .h1 {
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
}
.pb-70{
    padding-bottom: 70px;
}

.secondary-color-select{
    width: 100%;
    padding: 10px 15px;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    background: transparent;
    font-family: 'Oxygen',sans-serif;
    font-size: 15px;
}

.secondary-color-select:focus{
    outline: none;
}

@media screen and (max-width :600px) {
    .pb-80{
        padding-bottom: 80px !important;
    }
}
.icon-preview-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
    z-index: 3;
} 

.icon-preview-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
    background: white;
}
.wallpaper-preview-container {
    width: 100%;
    max-width: 540px;
    height: auto;
    aspect-ratio: 9/5;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

/* .wallpaper-preview-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    
} */
.circle{
    height: 25px;
    width: 25px;
    background-color: #e6e2e4;
    border-radius: 50%;
    margin-left: auto;
}

.wallpaper-container{
    width: auto;
    height: 200px;
    position: relative;
}

.icon-container{
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
}

.progress-chip{
    display: inline-block;
    padding: 0 20px;
    height: 30px;
    font-size: 15px;
    font-family: 'oxygen',sans-serif;
    line-height: 30px;
    border-radius: 25px;
    background-color: #f1f1f1;
}

.sticky-div {
    background-color: green;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
}

.start {
    height: 100px;
}

.end {
    height: 500px;
}

.obj-wallpaper{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.popup{
    position: fixed;
    background: rgba(0,0,0,0.1);
    width: 100%;
    height: 100vh;
    top: 0;
    left:0;
    z-index: 1000;
}

.popup-container{
    position: relative;
    max-width: 416px;
    margin: 0 auto;
    background: #fff;
    max-height: 85vh;
    margin-top: calc(100vh - 70vh);
    border-radius: 8px;
    padding:20px;
    overflow: auto;
    box-sizing: border-box;
}

@media screen and (max-width:500px) {
    .popup-container{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.mt-20{
    margin-top: 20px;
}
.overlay{
    background: rgba(0,0,0, 0.3);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    min-height: 150px;
}

.obj-detail{
    display: flex;
    align-items: flex-end;
    padding: 18px 18px;
    height: 100%;
    min-height: 150px;
}

.obj-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.obj-icon-mobile{
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.vcf-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-size: cover;
    padding:10px;
    border-radius: 10px;
    margin-left: 10px;
    min-height: 150px;
}

.vcf-card:last-child{
    margin-right: 10px;
}

.vcf-card.selected {
    box-shadow: 3px 4px 8px 0 var(--primary-color);
}

.no-white-space{
    white-space: nowrap;
}

.action-icon{
    width: 18px;
    height: 18px;
}

.status-container-card{
    margin-top: -10px;
    margin-right: -7px;
}

.approved-icon{
    width: 23px;
    height: 23px;
}

.sharing-status{
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
    border-radius: 4px;
}

.reject-sharing{
    color : #e77250;
    border: 1px solid #e77250;
}

.accept-sharing{
    color : #7bd11f;
    border: 1px solid #7bd11f;
}

.modify-sharing{
    color: #dfb928;
    border: 1px solid #dfb928;
}

/* @media (max-width : 480px) {
    .vcf-card{
        max-width: 350px;
    }
} */
.desktop-wallpaper-overlay{
    /* background: rgba(0,0,0, 0.3); */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    position: absolute;
}

.obj-wallpaper-container{
    width: 100%;
    /* height: 250px; */
    position: relative;
    /* margin-top: 15px; */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
    height: auto;
    aspect-ratio: 9/5;
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: cover; */
}

.process-detail-icon-container{
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: 100px;
    margin-top: -67px;
    position: relative;
}

.process-icon-detail{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

.process-name-container{
    height: 100%;
    display: flex;
    align-items: flex-end;
    color: white;
}
.goals-container{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    height: calc(100vh - 220px);
}

.goals-card{
    display: flex;
    flex-direction: row;
    background-position: center;
    background-size: cover;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    min-width: 286px !important;
}

.goals-desc{
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 0px !important;
    line-height: 20px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: black;
}

.goals-heading{
    font-size: 20px;
    font-weight: 600;
}

.goals-details-container{
    margin-top: 20px;
}

.w-250{
    width: 250px;
}

.goal-card-container:first-child{
    margin-left: 20px;
}

.max-width-200px{
    max-width: 200px;
}

/* .slick-track{
    margin-left: 0px;
    margin-right: 0px;
} */

/* .goal-card-container:last-child{
    margin-right: 20px;
} */
.primary-select-box{
    font-size: 15px;
    font-family: "Lato",sans-serif;
    width: 100%;
    padding: 10px 5px;
    border: 1px solid var(--secondary-text-color);
    border-radius: 4px;
    color: var(--primary-text-color-v2);
    background: transparent;
}

.primary-select-box:focus{
    border-color: var(--primary-color-v2);
    border-width: 2px;
    outline: none;
}

.primary-color-option{
    color: var(--primary-text-color-v2);
    font-size: 15px;
    font-family: "Lato",sans-serif;
    line-height: 20px;
    padding-bottom: 10px;
}

.upload-image{
    border: 1px dashed var(--secondary-color);
    width: 100%;
    line-height: 50px;
    background-color: white;
    border-radius: 8px;
    font-size: 18px;
    color: var(--secondary-color);
    font-family: 'Lato',sans-serif;
}

.mobile-upload-image{
    border: 1px dashed var(--secondary-color);
    line-height: 25px;
    background-color: white;
    border-radius: 8px;
    padding: 15px 10px;
    color: var(--secondary-color);
}

.graph-card-rectangle{
    width: 17rem !important;
    height: 9rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.graph-card-rectangle.selected{
    box-shadow: 3px 4px 8px 0 var(--primary-color);
}

.graph-card-rectangle:first-child{
    margin-left: 10px;
}

.metric-container{
    margin-right: 20px;
}

.metric-container:first-child{
    margin-left: 20px;
}

.metric-container:last-child{
    margin-right: 20px;
}

.desktop-metric-aggregate-height{
    height: calc(100vh - 308px);
    overflow: auto;
    margin-top: 10px;
}

.desktop-no-action-metric{
    height: calc(100vh - 260px);
}

.height-1_5rem{
    height: 1.5rem !important;
}

.font-size-16px{
    font-size: 16px !important;
}
.bar{
    width: 70px;
    height: 10px;
}

.progress-card{
    padding: 16px;
    flex: none;
    margin-right: 10px;
}

.progress-icon{
    width: 15px;
    height: 15px;
}

.goal-progress-popup-height{
    margin-top: calc(100vh - 93vh) !important;
    max-height: 83vh;
}

.progress-close-icon{
    position: absolute;
    top: 22px;
    right: 20px;
}
.goal-process-icon-img{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.goal-progress-icon{
    font-size: 13px;
    margin-right: 5px;
}
.metric-aggregate-card{
    height: 160px;
    width: 250px;
}

.desktop-metric-aggregate-card {
    height: 180px;
}

.metric-aggregate-card.selected, .desktop-metric-aggregate-card.selected {
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.75);
}
.gantt-chart{
    pointer-events: none;
}
.input-label.Mui-focused{
    color: var(--primary-color-v2) !important;
}

.input-label{
    font-size: 15px !important;
    font-family: 'Lato',sans-serif !important;
}

.group-select{
    font-size: 15px !important;
    font-family: 'Lato',sans-serif;
}

.group-select.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--primary-color-v2) !important;
}
.chart-container{
    margin: 10px;
}

.preview-graph-container{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 20px;
    /* background: rgba(0, 0, 0, 0.3); */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
}

.metric-name-container{
    background: rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 10px;
    bottom: 0px;
    border-top-right-radius: 10px;
}
.pie-container {
    height: 300px;
    width: 300px;
    position: relative;
    /* overflow: hidden; */
}

.learning-tab{
    background: white;
    margin-left: 10px;
    margin-right: 10px;
}

#segment1-container .pie {
    background-color: #f3a886;
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

#segment2-container,
#segment5-container {
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

#segment2-container .pie {
    background-color: #b0bef1;
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

#segment3-container,
#segment6-container {
    -webkit-transform:rotate(240deg);
            transform:rotate(240deg);
}

#segment3-container .pie {
    background-color: #a0ecb3;
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

#segment4-container .pie {
    background-color: rgb(255, 255, 199);
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

#segment5-container .pie {
    background-color: rgb(245, 191, 217);
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

#segment6-container .pie {
    background-color: rgb(255, 127, 127);
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
}

.hold {
    position:absolute;
    width:300px;
    height:300px;
    clip:rect(0px,300px,300px,150px);
    overflow: hidden;
}

.full-circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    background-color: rgb(255, 255, 199);
}

.pie {
    position:absolute;
    width:300px;
    height:300px;
    clip:rect(0px, 150px, 300px, 0px);
    border-radius:150px;
}

.slice1-heading{
    -webkit-transform: rotate( 240deg );
            transform: rotate( 240deg );
    width: 100px;
    height: 130px;
    margin-top: 30px;
    margin-left: 35px;
    padding: 5px;
    cursor: pointer;
}

.slice2-heading{
    -webkit-transform: rotate( 120deg );
            transform: rotate( 120deg );
    width: 150px;
    height: 100px;
    margin-top: 60px;
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
}

.slice3-heading{
    width: 100px;
    height: 120px;
    margin-top: 50px;
    margin-left: 20px;
    padding: 5px;
    cursor: pointer;
}

.inner-circle{
    height: 60px;
    width: 60px;
    background-color: #e6e2e4;
    border-radius: 50%;
    margin-left: auto;
    top: 41%;
    position: absolute;
    left: 40%;
}

.full-width {
    width: 100%;
    height: 100%;
}

#semi-circle-left {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

#semi-circle-left .pie {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-color: rgb(245, 191, 217);
}

#semi-circle-right .pie {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-color: rgb(255, 255, 199);
}

#semi-circle-right {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.arrow-circle {
    position: relative;
    display: flex;
    align-items: center ;
    justify-content: center;
    width: 330px;
    height: 330px;
    background-color: transparent;
    border-radius: 50%;
}

/* .active {
    color: black !important;
} */

.color-black{
    color: black !important;
}

.icon-preview-learning{
    margin-right: auto;
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    display: block;
    margin-left: auto;
}

.icon-preview-container-learning{
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 3rem;
    margin-left: 29px;
    -webkit-transform: rotate( 239deg );
            transform: rotate( 239deg );
}

.segment2{
    -webkit-transform: rotate( 122deg );
            transform: rotate( 122deg );
}

.segment3{
    -webkit-transform: rotate( 360deg );
            transform: rotate( 360deg );
    margin-top: 4rem;
}

.button-z-index {
    z-index: 500;
}

.two-line-overflow-hidden{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.clickable-area-semi-right {
    margin-top : 50px;
    margin-left : 40px;
    width : 110px;
    height : 200px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    padding: 5px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.clickable-area-semi-left {
    margin-top : 50px;
    margin-left : 40px;
    width : 110px;
    height : 200px;
    padding: 5px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.clickable-area-full {
    width : 210px;
    height: 210px;
    cursor: pointer;
}

.table thead th {
    vertical-align: middle !important;
}

.tab-font{
    font-size: 13px !important;
}

.transparent-bg{
    background: transparent !important;
}

.course-container{
    margin-right: 20px;
}

.course-container:first-child{
    margin-left: 20px;
}

.learning-aggregate-height{
    height: calc(100vh - 367px);
}

.desktop-learnings-aggregate-height{
    height: calc(100vh - 276px) !important;
    overflow: auto;
}

.overflowY-hidden{
    overflow-y: hidden;
}

.overflow-unset{
    overflow: unset !important;
}

.content-area-min-height-desktop {
    min-height: calc(100vh - 450px);
}

.pagination .page-item.active .page-link{
    background-color: var(--primary-color-v2);
    border-color: var(--primary-color-dark-v2);
}

.pagination .page-item .page-link{
    color: var(--primary-color-v2);
}

/* .tab-panel-container{
    background : linear-gradient(180deg, rgba(240,245,250,255), rgba(251,253,254,255));
    flex : 1;
    overflow-y: auto;
} */
.section-name {
    position: absolute;
    top: 128px;
    font-family: 'Lato',sans-serif;
    right: 15%;
    font-size: 60px;
}

.section-name-desktop{
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 60px;
    font-family: "Lato",sans-serif;
}
.learning-header{
    overflow: hidden;
    white-space: nowrap;
    width: 227px;
    text-overflow: ellipsis;
    font-size: 20px;
    align-self: center;
}

.learning-icon-container{
    width: 100px;
    height: 100px;
}

.learning-icon-preview{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
}
.filename{
    color: black !important;
    text-decoration: none !important;
}

.filename:hover{
    color: black !important;
    text-decoration: none !important;
}
.reference-container{
    margin-right: 15px;
    min-width: 270px;
}

.reference-container:last-child{
    margin-right: 20px;
}

.reference-container:first-child{
    margin-left: 20px;
}

.status-container{
    width: 121px;
}

.h-180{
    height: 180px;
}

.w-60 {
    width: 60%;
}

.course-status{
    width: 100%;
    padding: 5px 8px;
    border: 1px solid black;
    border-radius: 4px;
    background: transparent
}

.course-content-three-line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification-card{
    margin: auto;
    border-radius: 20px !important;
    padding: 0.75rem 0.5rem;
    margin-bottom: 10px;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}

.one-line-wrap{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex : 1 1;
    min-width: 0;
}

.compose-button{
    position: absolute !important;
    right: 20px;
    bottom: 10px;
}

.tab-height{
    height: calc(100vh - 180px);
}

.main-tab-height{
    height: calc(100vh - 164px);
    position: relative;
}

.inner-tab-height{
    height: calc(100vh - 213px);
    overflow: auto;
}

.inner-tab-height-with-search{
    height: calc(100vh - 252px );
    overflow: auto;
}

.reference-height-with-search{
    overflow: auto;
    height: calc(100vh - 203px);
}

.desktop-main-tab-height{
    overflow: auto;
    height: calc(100vh - 119px);
}

.desktop-inner-tab-height{
    height: calc(100vh - 178px);
    overflow: auto;
}

.desktop-reference-height{
    height: calc(100vh - 114px);
    overflow: auto;
}

.compose-button-desktop {
    position: fixed !important;
    right: 5%;
    bottom: 64px;
}

.notification-desktop-tab {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
}

.flex-none{
    flex: none;
}

.overflow-unset{
    overflow: unset;
}

.desktop-notification-tab-panel{
    margin-top: 39px;
    z-index: 1;
}

.desktop-compose-button{
    position: fixed !important;
    background-color: var(--primary-color-v2) !important;
    width: 40px;
    height: 40px;
    right: 5%;
    bottom: 15px;
    border-radius: 50% !important;

}

.content-area-height-not-and-ref {
    height: calc(100vh - 212px);
}

@media screen and (max-width: 599px) {
    .content-area-height-not-and-ref {
        height: calc(100vh - 204px);
    }

    .notification-subject {
        width: 150px;
    }
}

.notification-reference-card{
    border-radius: 20px !important;
    padding: 0.75rem 0.5rem;
}

.vh-70{
    height: 70vh;
}
.min-width-zero {
    min-width: 0;
}

.fs-10 {
    font-size: 10px;
}

.file-preview {
    aspect-ratio: 1/1;
}

.desktop-obj-icon{
    object-fit: cover;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.customize-input-box{
    border: none;
    padding: 5px;
    width: 100%;
    font-size: 14px;
    font-family: 'Lato',sans-serif;
}

.customize-input-box:hover,
.customize-input-box:focus{
    border-color: white;
    outline: none;
}

.desktop-compose-container{
    margin-left: 10%;
    margin-right: auto;
    max-width: 450px;
}

.compose-container{
    margin-left: 10%;
    margin-right: 10%;
    width: 100%;
    max-width: 450px;
    padding-bottom: 60px;
}

.autocompelte-input-box:focus{
    border-color: var(--primary-color-v2);
}
.h-78vh{
    height: 78vh;
}

.popup-height{
    margin-top: calc(100vh - 89vh) !important;
}

.action-container {
    height: 70%;
    /* background: #f1f1f1; */
    position: relative;
    padding: 0 !important;
}

.scroll-vertical {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.obj-card.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.obj-card.selected {
    border: 2px solid black;
}

.infinite-scroll-component{
    max-height: 200px !important;
    padding: 10px;
}

.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 15px;
    padding-left : 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.chip-title {
    font-size: 13px;
    color: gray;
    margin-right: 4px;
    font-family: "Lato",sans-serif;
}

.bg-light-fillet {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 2px 5px; 
}

.tag{
    color: var(--primary-text-color);
    border-radius: 20px !important;
    padding: 5px 10px !important;
    font-family: 'Oxygen',sans-serif;
    font-size: 12px;
}

.tag-remove{
    font-size: 15px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.node-label{
    font-family: 'Oxygen',sans-serif;
}

.dropdown{
    width: 100%;
}

.dropdown .dropdown-trigger{
    width: 100%;
    border-radius: 5px !important;
    border: 1px solid var(--secondary-text-color) !important;
    padding: 10px !important;
}

.tag-item{
    width: 88%;
}

.tag-item .search{
    width: 100%;
    border-bottom: none !important;
}
.color-grey{
    color: #565656;
}

.bread-crumb-box-shadow{
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.75)
}
.metric-card-container{
    padding: 15px 15px;
    border-radius: 10px !important;
    align-items: center;
}

.icon-45{
    width: 45px;
    height: 45px;
}
.pb-90{
    padding-bottom: 90px;
}
.obj-parent-card {
    border-radius: 8px;
    display: inline-block;
    color: black;
    max-width: 150px;
    object-fit: cover;
    cursor: pointer;
    min-width: 100px;
    margin: 10px;
}

.max-width-200px{
    max-width: 200px !important;
}

.obj-parent-card.active {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
}

.tree-overlay{
    background: rgba(0,0,0, 0.5);
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.px10-py12{
    padding: 0.75rem 12px;
}
.child-obj-card {
    border-radius: 8px;
    display: inline-block;
    color: white;
    max-width: 150px;
    object-fit: cover;
    cursor: pointer;
    min-width: 100px;
    margin: 10px;
}

.child-obj-card.active {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
}

.child-obj-card:last-child{
    margin-bottom: 10px;
}
.obj-path-card {
    padding: 12px 10px;
    border-radius: 8px;
    display: inline-block;
    background-color: #e1e1e1;
    color: grey;
}
.inbox-card-detail-container{
    margin-right: 15px;
    min-width: 200px;
}

.inbox-card-detail-container:first-child{
    margin-left: 15px;
}
.h-150-important{
    height: 150px !important
}

.icon-w80-h80{
    width: 80px !important;
    height: 80px !important;
}

.h-200{
    height: 200px !important;
}

.desktop-modify-icon{
    width: 100px;
    height: 100px;
    margin-top: -3rem !important;
}
.xyz{
    pointer-events: none;
    opacity: 0.6;
}
.no-action:last-child hr{
    display: none;
}

.vc-hl-line{
    margin-top: 14px;
    margin-bottom: 14px;
    border-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
    border-style: solid;
    margin-left: 10%;
    margin-right: 10%;
    height: 1px;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
    position: fixed;
}
.conference-overlay{
    margin-top: calc(100vh - 88vh) !important;
}
.inputbox{
    border: 1px solid var(--secondary-color);
    padding: 9px;
    border-radius: 4px;
}
.navbar-logo{
    font-weight: bold;
}

.navlink{
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
}

.navlink:hover{
    text-decoration: none !important;
    color: white;
}

.logout-button{
    margin-right: 12px;
    margin-left: 5px;
}

.nav-icons-container{
    display: flex;
    align-items: center;
}

.nav-bg{
    background-color: var(--primary-color-dark) !important;
}

@media screen and (max-width : 640px) {
    .logout-button{
        width: 80px;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .nav-icons-container{
        align-items: flex-start !important;
    }
}
.conference-card{
    padding : 1rem;
    border-radius: 20px;
}
.vid-preview-container {
    position: relative;
    height: 60vh;
    width: 100%;
}

.vid-preview-player {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.vid-preview-controls {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: end;
}

.h-88vh {
    height: 88vh;
}

.video-preview-container{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

@media only screen and (max-width: 600px){
    .vid-preview-container{
        height: 45vh !important;
        width: 100% !important;
    }

    .vc-join-button{
        width: 100% !important;
        margin-top: 10px;
    }

    .video-preview-container{
        flex-direction: column !important;
    }
}
.video-grid-container-row{
    position: relative;
    /* display: grid; */
    grid-gap: 10px;
}

#videos{
    position: relative;
    height: 85vh;
    width: 100vw;
    margin: auto;
    align-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    justify-items: center;
    align-items: center;
}

/* .controls p{
    padding: 10px;
    cursor: pointer;
    background: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
}

.controls p.on{
    background: #F7F7F7;
    color: #38373A;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
} */

.join{
    position: absolute;
    z-index: 1;
    width: 30vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    height: -moz-max-content;
    top: 50vh;
    left: 50vw;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 500px;
    max-width: 75vw;
}

.join  input{
    padding: 15px;
    font-size: 1rem;
    border-width: 1px;
    border-color: #38373A;
    border-style: solid;
    width: 80%;
    display: block;
    margin:  50px auto;
}

.join  button{
    min-width: 200px;
    padding: 12px 0;
    text-align: center;
    background-color: #38373A;
    color: #F7F7F7;
    border-width: 1px;
    border-color: #F7F7F7;
    border-style: solid;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.user-name{
    position: absolute;
    bottom: 0;
    color: white;
    left: 10px;
    font-size: 20px;
    margin-bottom: 0px;
}

.video-mute-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    left: -47%;
}
/* .video-muted-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.screen-sharing-container{
    height: 88vh;
    padding: 10px;
    grid-gap : 10px;
    display: grid;
    margin: auto;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.screen-sharing-container.cell-1{
    grid-row: 1; 
    grid-column: 1/4; 
}

.screen-sharing-3X3{
    grid-row: 1/3;
    grid-column: 1/4;
}

.screen-sharing-4X4{
    grid-row: 1/4;
    grid-column: 1/4;
}

.screen-sharing-2X2{
    grid-row: 1/2;
    grid-column: 1/3;
}

.screen-sharing-1X1{
    grid-row: 1;
    grid-column: 1/3;
} */

/* .screen-sharing-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 5px;
} */

/* .screen-sharing-three-grid-column{
    grid-template-columns: 1fr 1fr 1fr !important;
}

.four-grid-row-column{
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.video-muted-container{
    position: relative;
    background: #2b2323;
}

.video-chat-container{
    position: relative;
    height: 100%;
    width: 100%;
}

.h-65pc{
    height: 65% !important;
}

.h-50vh{
    height: 50vh !important;
}

.videos{
    position: relative;
    height: 90vh;
    display: grid;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 10px;
    grid-gap : 10px
}

.two-grid-column{
    grid-template-columns: 1fr 1fr;
}

.two-grid-row{
    grid-template-rows: 1fr 1fr;
}

.three-grid-column{
    grid-template-columns: 1fr 1fr 1fr;
}

.three-grid-row{
    grid-template-rows: 1fr 1fr 1fr;
}

.vid{
    height: 100%;
    width: 100%;
}

.video-muted{
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-muted-user-name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    width: 100px;
    height: 100px;
    background: rgb(5, 71, 5);
    border-radius: 50%;
    color: white;
    font-weight: 700;
}

.desktop-videos-two-column{
    position: relative;
    height: 90vh;
    display: grid;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 10px;
    grid-gap : 10px;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width : 600px) {
    
}

@media screen and (min-width : 961px) {

}

.grid-container-3-by-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
}

.grid-container {
    display: grid;
}

.grid-one-cross-two {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
}

.grid-two-cross-two {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.grid-two-cross-three {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.two-col-grid-span-full {
    grid-column: span 2;
} */

/* .grid-item {
    background-color: antiquewhite;
} */

.grid-container {
    display: grid;
    grid-gap: 1rem;
}

.one-cross-two-grid {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
}

.two-cross-one-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
}

.two-cross-two-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.two-cross-three-grid {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.three-cross-two-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.three-cross-three-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
}

.three-cross-four-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
}

.four-cross-two-grid {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
}

.span-two-wide{
    grid-column-start: 1;
    grid-column-end: 3;
}

.span-three-wide{
    grid-column-start: 1;
    grid-column-end: 4;
}

.span-two-tall{
    grid-row-start: 1;
    grid-row-end: 3;
}
.vid-controls {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: end;
}

.vid-user-name {
    background-color: white;
    border-radius: 1rem;
    padding: .25rem 1rem;
    color: var(--primary-text-color-v2);
    max-width: 200px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}
.controls{
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
    max-width: 800px;
}

.profile-img{
    width: 49px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

/* @media screen and (max-width : 768px) {
    .controls{
        left: 30% !important;
    }
} */
.mr-ml-10{
    margin-left: 10px;
    margin-right: 10px;
}

.video-content-area {
    height: calc(100vh - 56px);
}
.members-card{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0.75rem 1rem;
}

.member-icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.member-status{
    display: flex;
    margin-left: auto;
    padding: 10px;
}

.w-100percentage{
    width: 100%;
}
.member-list-container{
    /* margin-right: 210px; */
    padding-top: 25px;
}

.member-list-style{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 5px;
}

.member-list-style:last-child{
    border-bottom: none !important;
}

.icon-button{
    display: flex !important;
    justify-content: flex-end;
}

@media screen and (max-width : 600px) {
    .member-list-container{
        margin-right: 0px !important;
        padding-top: 0px !important;
    }
}
.non-invited-member-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary-color);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.non-invited-member-container:last-child{
    border-bottom: none !important;
    margin-bottom: 0px !important;
}
.member-detail{
    border-bottom: 1px solid gray;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.lh-13{
    line-height: 13px;
}

.member-detail:last-child{
    border-bottom: none !important;
    margin-bottom: 0px !important;
}
.content-area-height-members {
    height: calc(100vh - 215px);
}

.content-area-height-members-for-users {
    height: calc(100vh - 165px);
}

.content-area-height-members-for-users-desktop {
    height: calc(100vh - 110px);
}

@media screen and (max-width: 599px) {
    .content-area-height-members {
        height: calc(100vh - 207px);
    }

    .content-area-height-members-for-users {
        height: calc(100vh - 157px);
    }
}
.role-heading{
    font-size: 15px;
    color: rgb(85, 82, 82);
}

.role-profile{
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}

.member-profile{
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 50%;
    padding: 2px;
    background: var(--primary-color);
}

.word-break-all{
    word-break: break-all;
}

.member-detail-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

@media screen and (max-width : 700px) {
    .member-detail-grid{
        grid-template-columns: 1fr 1fr !important;
    }
}
@media screen and (max-width:700px){
    .popup-80vh{
        margin-top: calc(100vh - 80vh) !important;
    }
}
.tm-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border : none !important;
    border-radius: 20px !important ;
}

.tm-profile{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.pending-action-25{
    margin-left: auto;
    margin-right: 10px;
    background: var(--primary-color-dark);
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
}

.pending-action-30{
    margin-left: auto;
    margin-right: 10px;
    background: var(--primary-color-dark);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pending-action-35{
    margin-left: auto;
    margin-right: 10px;
    background: var(--primary-color-dark);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pending-number{
    text-align: center;
    display: block;
}
.department-list{
    list-style: none;
    display: flex;
    align-items: center;
    width: 100%;
    /* border-bottom: 1px solid #dfdfdf; */
}

.department-button-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.department-list-container li:last-child {
    border-bottom: none;
}

.department-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
.branchPopup{
    max-width: 576px !important;
    margin-top: calc(100vh - 85vh) !important;
}

@media screen and (max-width : 700px) {
    .branchPopup{
        margin-top: calc(100vh - 90vh) !important;
    }  
}

.close-btn-right {
    position: absolute;
    right: 1em;
}
.list-container li:last-child {
    border-bottom: none;
}

.branch-list{
    list-style: none;
    display: flex;
    width: 100%;
    padding-bottom: 10px;
}

.branch-address{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}
.gig-user-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.gig-user-card{
    width: 100%;
    border-radius: 20px !important;
    padding: 10px 20px;
}

.gig-user-inner-card{
    border-radius: 10px !important;
    padding: 10px 15px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    flex-direction: row !important;
}

.w-400{
    width: 400px;
}
.css-1g86id8-MuiTreeItem-content.Mui-selected{
    background-color: transparent !important;
}

.h-80vh{
    height: 80vh;
}
.review-container{
    padding-top: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    flex:1 1;
}
.border-rl{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.nested-tab{
    border-bottom: none;
    background: white;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 10px;
}

.sub-obj-container{
    width: 294px;
    margin-bottom: 20px;
}

.sub-obj-container.selected {
    border-radius: 20px;
    box-shadow: 3px 4px 8px var(--primary-color);
    /* margin-bottom: 20px; */
}

.sub-obj-container:first-child{
    margin-left: 10px;
}

.sub-obj-container:last-child{
    margin-right: 10px;
}

.sub-obj-action-container{
    position: absolute;
    top: 7px;
    width: 30px;
    right: 9px;
}

.action-img{
    background: rgba(0, 0, 0, 0.2);
    padding: 4px;
    border-radius: 50%;
}

.action-img-bardge{
    width: 25px;
    height: 25px;
    background: rgba(0,0,0,0.2);
    padding: 2px;
    border-radius: 50%;
}

.sub-obj-detail{
    border: none !important;
    margin: auto;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 0.75rem 1rem;
}

.sub-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.sub-obj-wallpaper{
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.drill-button-container{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
}

.red-bg{
    background: rgb(255 0 0 / 50%) !important;
}
.box{
    background-color: #1e8d1e;
    height: 24px;
    width: 58px;
    position:relative;
    text-align: right;
    margin:0 0 4px 30px;
    color: white;
    font-family: 'oxygen',sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
}

.box:before{
    content:"";
    position:absolute;
    top:0;
    right:58px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid #1e8d1e;
    border-bottom: 12px solid transparent;
}

.red-tag{
    background-color: red !important;
}

.red-tag:before{
    border-right: 12px solid red !important;
}

.yellow-tag{
    background-color: #ffdf30 !important;
}

.yellow-tag:before{
    border-right: 12px solid #ffdf30 !important;
}

.action-button-container{
    position: fixed;
    left: 51%;
    bottom: 10px;
    -webkit-transform: translate(-40%, -40%);
            transform: translate(-40%, -40%);
    margin: 0 auto;
    width: 300px;
}

.link-button{
    flex: 1 1;
    font-size: 15px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accept-link{
    background-color: var(--primary-color);
    font-family: 'oxygen', sans-serif;
    font-weight: 600;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid var(--primary-color);
}

.modify-link{
    background-color: var(--tertiary-color);
    font-family: 'oxyygen',sans-serif;
    font-weight: 600 ;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid var(--tertiary-color);
}

.reject-link{
    background-color: var(--secondary-color-light );
    font-family: 'oxygen', sans-serif;
    font-weight: 600 ;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid var(--secondary-color-light);
}

.p-42{
    padding-bottom: 42px;
}
.text-field-static {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    color: var(--primary-text-color);
    border-radius: 4px;
    background-color: #f2f2f2;
}

.h-40{
    height: 40px;
}
.modify-learning-card{
    padding: 10px;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 4px !important;
    display: inline-flex !important;
    border: unset !important;
    background-clip: unset !important;
}

.mobile-modify-learning-card{
    padding: 10px;
    align-items: center;
    margin-bottom: 4px;
    border-radius: 4px !important;
    display: inline-flex !important;
    border: unset !important;
    background-clip: unset !important;
}

.mobile-modify-learning-card.selected{
    box-shadow: 3px 3px 7px 1px rgb(0 0 0 / 60%)
}

.mobile-course-card{
    border-radius: 4px !important;
    margin-bottom: 11px;
    margin-right: 20px !important;
    flex: none;
    border: unset !important;
    background-clip: unset !important;
    max-width: 160px;
}

.mobile-course-card.selected{
    box-shadow: 3px 3px 7px 1px rgb(0 0 0 / 60%)
}

.mobile-course-card:first-child{
    margin-left: 20px;
}

.modify-learning-course-card{
    border-radius: 4px !important;
    margin-bottom: 30px;
    margin-right: 30px !important;
    flex: none;
    border: unset !important;
    background-clip: unset !important;
    max-width: 160px;
}

.modify-learning-course-card:first-child{
    margin-left: 20px;
}

.modify-learning-course-card.selected {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
    /* transform: scale(1.2); */
}

.modify-learning-card.selected {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
    /* transform: scale(1.2); */
}

.learning-area-icon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.reset-password-container{
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.subscription-card{
    padding : 10px 15px;
    border-radius: 10px !important;
}
.about-us-outer-image-container{
    width: 300px;
    height: 450px;
    border-radius: 10px;
    border: 3px solid var(--primary-color-v2);
    margin-top: 80px;
    margin-left: -80px;
    box-shadow: 6px 6px 7px 0 #4d78d64d, 3px 3px 8px 0 #4d78d64d inset
}

.about-us-image{
    width: 300px;
    height: 450px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

@media screen and (max-width : 660px) {
    .about-us-outer-image-container{
        width: 220px;
        height: 350px;
        margin-left: -40px;
        margin-top: 40px;
    }

    .about-us-image{
        width: 220px;
        height: 350px;
    }
}
.update-subscribe-container {
    margin-left: 20%;
    margin-right: auto;
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    .update-subscribe-container{
        margin-left: auto;
        margin-right: auto;
    }
}
.custom-list {
    list-style: none; /* Remove default bullets */
}

.custom-list li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: var(--primary-color-v2); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 2em; /* Also needed for space (tweak if needed) */
    margin-left: -2em; /* Also needed for space (tweak if needed) */
}

.list-style-none{
    list-style: none;
}

.list-icon{
    width: 2.5em;
    margin-left: -2.5em;
    font-size: 19px;
}
.h-280px{
    min-height: 280px;
}
