.navbar-logo{
    font-weight: bold;
}

.navlink{
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
}

.navlink:hover{
    text-decoration: none !important;
    color: white;
}

.logout-button{
    margin-right: 12px;
    margin-left: 5px;
}

.nav-icons-container{
    display: flex;
    align-items: center;
}

.nav-bg{
    background-color: var(--primary-color-dark) !important;
}

@media screen and (max-width : 640px) {
    .logout-button{
        width: 80px;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .nav-icons-container{
        align-items: flex-start !important;
    }
}