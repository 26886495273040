.fs-6px{
    font-size: 6px;
}

.fs-8px{
    font-size: 8px;
}

.fs-10{
    font-size: 10px;
}

.secondary-color-light{
    color : rgb(197 197 205);
}

.mb-2px{
    margin-bottom: 2px;
}