.section-name {
    position: absolute;
    top: 128px;
    font-family: 'Lato',sans-serif;
    right: 15%;
    font-size: 60px;
}

.section-name-desktop{
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 60px;
    font-family: "Lato",sans-serif;
}