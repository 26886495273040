.border-rl{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.nested-tab{
    border-bottom: none;
    background: white;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 10px;
}