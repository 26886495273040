.grid-column-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.corporate-profile-grid{
    margin-top: 12px;
    margin-bottom: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 12px;
}

@media screen and (max-width : 700px) {
    .grid-column-2{
        grid-template-columns: none;
    }

    .corporate-profile-grid{
        grid-template-columns: 1fr 1fr;
    }
}