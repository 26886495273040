.metric-aggregate-card{
    height: 160px;
    width: 250px;
}

.desktop-metric-aggregate-card {
    height: 180px;
}

.metric-aggregate-card.selected, .desktop-metric-aggregate-card.selected {
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.75);
}