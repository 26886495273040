.min-width-zero {
    min-width: 0;
}

.fs-10 {
    font-size: 10px;
}

.file-preview {
    aspect-ratio: 1/1;
}

.desktop-obj-icon{
    object-fit: cover;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}