.primary-select-box{
    font-size: 15px;
    font-family: "Lato",sans-serif;
    width: 100%;
    padding: 10px 5px;
    border: 1px solid var(--secondary-text-color);
    border-radius: 4px;
    color: var(--primary-text-color-v2);
    background: transparent;
}

.primary-select-box:focus{
    border-color: var(--primary-color-v2);
    border-width: 2px;
    outline: none;
}

.primary-color-option{
    color: var(--primary-text-color-v2);
    font-size: 15px;
    font-family: "Lato",sans-serif;
    line-height: 20px;
    padding-bottom: 10px;
}
