.sub-obj-container{
    width: 294px;
    margin-bottom: 20px;
}

.sub-obj-container.selected {
    border-radius: 20px;
    box-shadow: 3px 4px 8px var(--primary-color);
    /* margin-bottom: 20px; */
}

.sub-obj-container:first-child{
    margin-left: 10px;
}

.sub-obj-container:last-child{
    margin-right: 10px;
}

.sub-obj-action-container{
    position: absolute;
    top: 7px;
    width: 30px;
    right: 9px;
}

.action-img{
    background: rgba(0, 0, 0, 0.2);
    padding: 4px;
    border-radius: 50%;
}

.action-img-bardge{
    width: 25px;
    height: 25px;
    background: rgba(0,0,0,0.2);
    padding: 2px;
    border-radius: 50%;
}

.sub-obj-detail{
    border: none !important;
    margin: auto;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    padding: 0.75rem 1rem;
}

.sub-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.sub-obj-wallpaper{
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}