.footer-container{
    color: white !important;
    display: flex !important;
    justify-content: space-evenly;
    min-height: 40px;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 8px;
    width: 86%;
    margin-left: auto;
    margin-right: auto;
}

.footer-link{
    text-align: center;
    margin-bottom: 0px !important;
    color: white !important;
    text-decoration: none;
    margin-right: 10px;
}

.footer-link:hover{
    text-decoration: none;
}

@media screen and (max-width : 660px) {
    .footer-container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .footer-link{
        padding-bottom: 8px;
    }
}