.content-area-height-members {
    height: calc(100vh - 215px);
}

.content-area-height-members-for-users {
    height: calc(100vh - 165px);
}

.content-area-height-members-for-users-desktop {
    height: calc(100vh - 110px);
}

@media screen and (max-width: 599px) {
    .content-area-height-members {
        height: calc(100vh - 207px);
    }

    .content-area-height-members-for-users {
        height: calc(100vh - 157px);
    }
}