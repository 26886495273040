.button {
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    color: #000;
    border: none;
    border-radius: 5px;
    background-color: rgb(252, 221, 183);
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.label-heading{
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.required:after {
    content:" *";
    color: red;
}

.subscription-label{
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    margin-bottom: 0.5rem;
}

.textarea-container{
    width: 100%;
    border-radius: 4px;
    border: 1px solid black;
    height: 70px;
    padding: 10px;
}

.authentication-card {
    padding: .75rem 2rem;
    margin-left: auto ;
    margin-right: auto;
    border: none !important;
    max-width: 1000px;
}

.auth-heading{
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

.subscription-amount-container{
    display: flex;
    margin-left: 0.7rem;
}

.subscription-amount{
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
    display: flex;
    margin-bottom: 0.5rem;
    padding-left: 15px;
}