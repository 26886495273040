.modify-learning-card{
    padding: 10px;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 4px !important;
    display: inline-flex !important;
    border: unset !important;
    background-clip: unset !important;
}

.mobile-modify-learning-card{
    padding: 10px;
    align-items: center;
    margin-bottom: 4px;
    border-radius: 4px !important;
    display: inline-flex !important;
    border: unset !important;
    background-clip: unset !important;
}

.mobile-modify-learning-card.selected{
    box-shadow: 3px 3px 7px 1px rgb(0 0 0 / 60%)
}

.mobile-course-card{
    border-radius: 4px !important;
    margin-bottom: 11px;
    margin-right: 20px !important;
    flex: none;
    border: unset !important;
    background-clip: unset !important;
    max-width: 160px;
}

.mobile-course-card.selected{
    box-shadow: 3px 3px 7px 1px rgb(0 0 0 / 60%)
}

.mobile-course-card:first-child{
    margin-left: 20px;
}

.modify-learning-course-card{
    border-radius: 4px !important;
    margin-bottom: 30px;
    margin-right: 30px !important;
    flex: none;
    border: unset !important;
    background-clip: unset !important;
    max-width: 160px;
}

.modify-learning-course-card:first-child{
    margin-left: 20px;
}

.modify-learning-course-card.selected {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
    /* transform: scale(1.2); */
}

.modify-learning-card.selected {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
    /* transform: scale(1.2); */
}

.learning-area-icon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}