.vcf-card-square-desktop.selected, .vcf-card-square.selected {
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.75);
}

.vcf-card-square-desktop {
    display: flex;
    /* height: 280px; */
    flex-direction: column;
}

.vcf-card-square {
    display: flex;
    /* height: 220px; */
    flex-direction: column;
}

.shared-status-container{
    position: absolute;
    right: 10px;
    top: 10px;
}

.cursor-unset{
    cursor: unset;
}

.no-icon-card-height{
    height: 250px !important;
}