.box{
    background-color: #1e8d1e;
    height: 24px;
    width: 58px;
    position:relative;
    text-align: right;
    margin:0 0 4px 30px;
    color: white;
    font-family: 'oxygen',sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
}

.box:before{
    content:"";
    position:absolute;
    top:0;
    right:58px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 12px solid #1e8d1e;
    border-bottom: 12px solid transparent;
}

.red-tag{
    background-color: red !important;
}

.red-tag:before{
    border-right: 12px solid red !important;
}

.yellow-tag{
    background-color: #ffdf30 !important;
}

.yellow-tag:before{
    border-right: 12px solid #ffdf30 !important;
}

.action-button-container{
    position: fixed;
    left: 51%;
    bottom: 10px;
    transform: translate(-40%, -40%);
    margin: 0 auto;
    width: 300px;
}

.link-button{
    flex: 1;
    font-size: 15px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accept-link{
    background-color: var(--primary-color);
    font-family: 'oxygen', sans-serif;
    font-weight: 600;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid var(--primary-color);
}

.modify-link{
    background-color: var(--tertiary-color);
    font-family: 'oxyygen',sans-serif;
    font-weight: 600 ;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid var(--tertiary-color);
}

.reject-link{
    background-color: var(--secondary-color-light );
    font-family: 'oxygen', sans-serif;
    font-weight: 600 ;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid var(--secondary-color-light);
}

.p-42{
    padding-bottom: 42px;
}