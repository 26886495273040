.desktop-compose-container{
    margin-left: 10%;
    margin-right: auto;
    max-width: 450px;
}

.compose-container{
    margin-left: 10%;
    margin-right: 10%;
    width: 100%;
    max-width: 450px;
    padding-bottom: 60px;
}

.autocompelte-input-box:focus{
    border-color: var(--primary-color-v2);
}