.pb-70{
    padding-bottom: 70px;
}

.secondary-color-select{
    width: 100%;
    padding: 10px 15px;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    background: transparent;
    font-family: 'Oxygen',sans-serif;
    font-size: 15px;
}

.secondary-color-select:focus{
    outline: none;
}

@media screen and (max-width :600px) {
    .pb-80{
        padding-bottom: 80px !important;
    }
}