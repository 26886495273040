.icon-preview-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -2rem;
    z-index: 3;
} 

.icon-preview-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);
    background: white;
}