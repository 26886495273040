.non-invited-member-container{
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary-color);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.non-invited-member-container:last-child{
    border-bottom: none !important;
    margin-bottom: 0px !important;
}