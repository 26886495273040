.action-container {
    height: 70%;
    /* background: #f1f1f1; */
    position: relative;
    padding: 0 !important;
}

.scroll-vertical {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.obj-card.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.obj-card.selected {
    border: 2px solid black;
}

.infinite-scroll-component{
    max-height: 200px !important;
    padding: 10px;
}

.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 15px;
    padding-left : 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-right: 3px;
    margin-bottom: 3px;
}

.chip-title {
    font-size: 13px;
    color: gray;
    margin-right: 4px;
    font-family: "Lato",sans-serif;
}

.bg-light-fillet {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 2px 5px; 
}

.tag{
    color: var(--primary-text-color);
    border-radius: 20px !important;
    padding: 5px 10px !important;
    font-family: 'Oxygen',sans-serif;
    font-size: 12px;
}

.tag-remove{
    font-size: 15px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.node-label{
    font-family: 'Oxygen',sans-serif;
}

.dropdown{
    width: 100%;
}

.dropdown .dropdown-trigger{
    width: 100%;
    border-radius: 5px !important;
    border: 1px solid var(--secondary-text-color) !important;
    padding: 10px !important;
}

.tag-item{
    width: 88%;
}

.tag-item .search{
    width: 100%;
    border-bottom: none !important;
}