.left-vertical-menu-bar {
    width: 70px;
    background-color: var(--primary-color-v2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed; 
    top: 0px; 
    bottom: 0px;
    /* z-index:"1111"; */
    margin-top: 64px;
}

.process-bg {
    background-color: var(--primary-color-dark-v2);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.process-navlink {
    text-decoration: none;
}

.process-navlink.active {
    border-left: 3.5px solid white;
}