.child-obj-card {
    border-radius: 8px;
    display: inline-block;
    color: white;
    max-width: 150px;
    object-fit: cover;
    cursor: pointer;
    min-width: 100px;
    margin: 10px;
}

.child-obj-card.active {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
}

.child-obj-card:last-child{
    margin-bottom: 10px;
}