.company-view-sidebar{
    min-width: 320px;
    max-width: 320px;
    border-radius: 10px;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
    background-color: var(--secondary-color-v2);
    height: 100%;
    transition: transform 1s ease-in-out;
    /* -webkit-transition: right .3s ease-in-out; */
    transform: translateX(-50%)
}

.sidebar-container {
    position: sticky;
    top: 76px;
    min-height: calc(100vh - 365px);
    max-height: calc(100vh - 100px);
}

.company-view-sidebar-content {
    overflow-y: auto;
    max-height: calc(100vh - 157px);
}