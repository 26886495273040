.gig-user-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.gig-user-card{
    width: 100%;
    border-radius: 20px !important;
    padding: 10px 20px;
}

.gig-user-inner-card{
    border-radius: 10px !important;
    padding: 10px 15px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    flex-direction: row !important;
}

.w-400{
    width: 400px;
}