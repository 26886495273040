.drill-button-container{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
}

.red-bg{
    background: rgb(255 0 0 / 50%) !important;
}