.obj-parent-card {
    border-radius: 8px;
    display: inline-block;
    color: black;
    max-width: 150px;
    object-fit: cover;
    cursor: pointer;
    min-width: 100px;
    margin: 10px;
}

.max-width-200px{
    max-width: 200px !important;
}

.obj-parent-card.active {
    box-shadow: 3px 4px 8px 0 rgb(0 0 0 / 75%);
}

.tree-overlay{
    background: rgba(0,0,0, 0.5);
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.px10-py12{
    padding: 0.75rem 12px;
}