.width-150{
    width : 150px !important;
}

.grey-select-box{
    width: 100%;
    padding: 10px 5px;
    border: 1px solid var(--secondary-text-color);
    border-radius: 4px;
    color: var(--primary-text-color);
    font-family: 'Lato',sans-serif;
    background: transparent;
}

.grey-select-box:focus{
    outline: none;
    border : 2px solid var(--primary-color-v2);
}