.vid-preview-container {
    position: relative;
    height: 60vh;
    width: 100%;
}

.vid-preview-player {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.vid-preview-controls {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: end;
}

.h-88vh {
    height: 88vh;
}

.video-preview-container{
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

@media only screen and (max-width: 600px){
    .vid-preview-container{
        height: 45vh !important;
        width: 100% !important;
    }

    .vc-join-button{
        width: 100% !important;
        margin-top: 10px;
    }

    .video-preview-container{
        flex-direction: column !important;
    }
}