.tm-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border : none !important;
    border-radius: 20px !important ;
}

.tm-profile{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.pending-action-25{
    margin-left: auto;
    margin-right: 10px;
    background: var(--primary-color-dark);
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
}

.pending-action-30{
    margin-left: auto;
    margin-right: 10px;
    background: var(--primary-color-dark);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pending-action-35{
    margin-left: auto;
    margin-right: 10px;
    background: var(--primary-color-dark);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pending-number{
    text-align: center;
    display: block;
}