.h-150-important{
    height: 150px !important
}

.icon-w80-h80{
    width: 80px !important;
    height: 80px !important;
}

.h-200{
    height: 200px !important;
}

.desktop-modify-icon{
    width: 100px;
    height: 100px;
    margin-top: -3rem !important;
}